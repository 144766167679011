import React from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import './SideMenu.scss';

function SideMenu({
  languageList,
  showLanguageMenu,
  setShowLanguageMenu,
  handleLanguageChange,
  handleLogout,
  urlPathname,
}) {
  return (
    <Drawer
      anchor={'right'}
      open={showLanguageMenu}
      onClose={() => setShowLanguageMenu(false)}
      PaperProps={{
        sx: { width: '65%' },
      }}
    >
      <Box sx={{ padding: '0.2rem 0.5rem' }} data-test-id="menu-container">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon onClick={() => setShowLanguageMenu(false)} />
        </Box>
        <List>
          {urlPathname === 'identity' && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ListItemText
                primary={<FormattedMessage id="confirmation_link_item_third" />}
                style={{ marginLeft: '2.5rem' }}
                onClick={handleLogout}
              />
              <hr style={{ margin: '0.5rem' }} />
            </Box>
          )}
          <Box
            sx={{ display: 'flex', alignItems: 'center' }}
            data-test-id="language"
          >
            <LanguageIcon
              color="primary"
              style={{ margin: '0.5rem' }}
              aria-label="Language Icon"
              data-test-id="language-icon"
            />
            <ListItemText
              sx={{ color: '#005ea2' }}
              primary={<FormattedMessage id="language_label_header" />}
              data-test-id="language-text"
            />
          </Box>
          {languageList
            .filter((lang) => lang.value !== 1)
            .map((lang) => (
              <ListItem
                key={lang.label}
                disablePadding
                onClick={() => {
                  handleLanguageChange(lang.value);
                  if (lang.value !== 1) {
                    setShowLanguageMenu(false);
                  }
                }}
              >
                <ListItemButton sx={{ marginLeft: '1.5rem' }}>
                  <ListItemText primary={lang.label} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default SideMenu;
