import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: 0,
  zipcode: '',
  loading: false,
  data: {},
  error: false,
};

const alternateAddressSearchReducer = createSlice({
  name: 'alternateAddressSearch',
  initialState,
  reducers: {
    updateAlternateAddressFormData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateAlternateAddressFormData } =
  alternateAddressSearchReducer.actions;

export default alternateAddressSearchReducer.reducer;
