import * as yup from 'yup';

const addressSearchSchema = yup
  .object({
    firstName: yup
      .string()
      .required('fn_mainError')
      .matches(/^(?!['\s-])[A-Za-z' -]*$/, 'fn_mainError,fn_subError'),
    lastName: yup
      .string()
      .required('ln_mainError')
      .matches(/^(?!['\s.-])[A-Za-z'.\s-]*$/, 'ln_mainError,ln_subError'),
    email: yup
      .string()
      .required('email_mainError')
      .matches(
        /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/,
        'email_mainError,email_subError'
      ),
    streetAddress1: yup
      .string()
      .required('addr1_mainError')
      .matches(
        /^(?!['\s./-])[A-Za-z0-9#\s'./-]*$/,
        'addr1_mainError,addr1_subError'
      ),
    streetAddress2: yup
      .string()
      .matches(
        /^(?!['\s./-])[A-Za-z0-9#\s'./-]*$/,
        'addr2_mainError,addr2_subError'
      ),
    city: yup
      .string()
      .required('city_mainError')
      .matches(/^(?!['\s.-])[A-Za-z'.\s-]*$/, 'city_mainError,city_subError'),
    state: yup
      .string()
      .required('state_mainError')
      .max(2, 'state_mainError')
      .min(2, 'state_mainError'),
    zipcode: yup
      .string()
      .required('zipcode_mainError')
      .matches(
        /^([0-9]{5})(?:-[0-9]{4})?$/,
        'zipcode_mainError,zipcode_subError'
      ),
  })
  .required();

export const alternateAddressSearchSchema = yup
  .object({
    streetAddress1: yup
      .string()
      .required('addr1_mainError')
      .matches(
        /^(?!['\s./-])[A-Za-z0-9#\s'./-]*$/,
        'addr1_mainError,addr1_subError'
      ),
    streetAddress2: yup
      .string()
      .matches(
        /^(?!['\s./-])[A-Za-z0-9#\s'./-]*$/,
        'addr2_mainError,addr2_subError'
      ),
    city: yup
      .string()
      .required('city_mainError')
      .matches(/^(?!['\s.-])[A-Za-z'.\s-]*$/, 'city_mainError,city_subError'),
    state: yup
      .string()
      .required('state_mainError')
      .max(2, 'state_mainError')
      .min(2, 'state_mainError'),
    zipcode: yup
      .string()
      .required('zipcode_mainError')
      .matches(
        /^([0-9]{5})(?:-[0-9]{4})?$/,
        'zipcode_mainError,zipcode_subError'
      ),
  })
  .required();

export default addressSearchSchema;
