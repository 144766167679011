import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  getUrlParams,
  BASE_URL,
  scrollToTop,
  useAnalyticsEventTracker,
} from '../../utils/utils';
import Button from '../../common/Button';
import Header from '../../pages/Header';
import './Confirmation.scss';
import baseConfig from '../../utils/config';
import states, { richText } from '../../i18n/states';

const Login = () => {
  const gaEventTracker = useAnalyticsEventTracker('Confirmation Page');
  const query = getUrlParams();
  const routeParams = useParams();
  const { state } = routeParams;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lang_cd = searchParams.get('lang_cd');
  const { formatMessage } = useIntl();
  const faq_link = formatMessage({
    id: 'faq_link',
  });

  const swaXid = states[state]?.showSwaXid === true ? '' : query.swa_xid;

  useEffect(() => {
    const name = query?.name;
    const completion_date = query?.completion_date;
    if (!name || !completion_date) {
      sessionStorage.setItem(
        'state_errorType',
        JSON.stringify({ type: 'WebAddressIncomplete' })
      );
      gaEventTracker(`Null values on confirmation page`);
      navigate(
        `/error/${state}/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
        {
          state: { type: 'WebAddressIncomplete' },
        }
      );
    }
  }, []);

  const handleLogout = async () => {
    const config = {
      method: 'GET',
      url: `${BASE_URL}v1/logindotgov/logout?return_url=true&lang-cd=${
        lang_cd || baseConfig.defaultLanguage
      }`,
      headers: {
        // prettier-ignore
        'swa': state,
        'swa-xid': query.swa_xid,
      },
    };

    try {
      gaEventTracker(`${state}-Logout Button Clicked`);
      const response = await axios(config);
      window.location.href = response.data.url;
    } catch (error) {
      sessionStorage.setItem(
        'state_errorType',
        JSON.stringify({ type: 'WebAddressIncomplete' })
      );
      gaEventTracker(`${state}-Logout is not success`);
      navigate(
        `/error/${state}/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
        {
          state: { type: 'WebAddressIncomplete' },
        }
      );
    }
  };

  return (
    <>
      <Header handleLogout={handleLogout} />
      <div className="confirmation" id="mainContent">
        <section className="grid-container first-section">
          <h1
            data-test-id="login-confirmation-header"
            className="confirmation-header"
          >
            <FormattedMessage id="confirmation_header" />
          </h1>
        </section>
        <hr className="hr" />
        <section className="grid-container">
          <h3
            data-test-id="login-confirmation-name"
            className="confirmation-name"
          >
            {query.name}
            <FormattedMessage id="confirmation_name" />
          </h3>
          <div
            data-test-id="login-confirmation-checkmark-section"
            className="checkmark-section"
          >
            <img
              className="checkmark"
              src="static/img/checkmark.svg"
              alt="checkmark"
            />
            <div>
              <FormattedMessage id="confirmation_checkmark" />{' '}
              <FormattedMessage id="confirmation_state_title" />{' '}
              <FormattedMessage id="confirmation_date_seperator" />{' '}
              {query.completion_date}
            </div>
          </div>
          <div
            data-test-id="login-confirmation-sub-text"
            className="confirmation-sub-text"
          >
            <FormattedMessage id="confirmation_swa_id_text" /> {swaXid}
          </div>
          <hr className="hr-end" />
        </section>
        <section className="grid-container">
          <h3
            data-test-id="login-confirmation-next-step"
            className="confirmation-next-step"
          >
            <FormattedMessage id="confirmation_next_step_text" />
          </h3>
          <div className="list-style1">
            <div className="list-style1-extra">
              <FormattedMessage
                id="confirmation_content_extra"
                values={{ ...richText }}
              />
            </div>
            <ul>
              <a data-test-id="login-confirmation-content-line-first">
                <FormattedMessage
                  id="confirmation_steps_content_line_first"
                  values={{ ...richText }}
                />
              </a>
              <a data-test-id="login-confirmation-content-line-second">
                <FormattedMessage
                  id="confirmation_steps_content_line_second"
                  values={{ ...richText }}
                />
              </a>
            </ul>
            <div className="next-step-section">
              <a data-test-id="login-confirmation-nextStep-line-first">
                <FormattedMessage
                  id="confirmation_steps_nextStep_line_first"
                  values={{ ...richText }}
                />
              </a>
              <a data-test-id="login-confirmation-nextStep-line-second">
                <FormattedMessage
                  id="confirmation_steps_nextStep_line_second"
                  values={{ ...richText }}
                />
              </a>
            </div>
          </div>
        </section>
        {states[state]?.showFAQButton === 'true' && (
          <div className="FAQ-button">
            <Button data-test-id="login-confirmation-button" type="primary">
              <a href={faq_link} className="a-link">
                <FormattedMessage id="confirmation_button_text" />
              </a>
            </Button>
          </div>
        )}
        <section className="grid-container section3">
          <h3
            data-test-id="login-confirmation-information"
            className="confirmation-information"
          >
            <FormattedMessage
              id="uicp_more_info_text"
              values={{ ...richText }}
            />
          </h3>
          <div
            data-test-id="login-confirmation-content-info"
            className="list-style1-extra"
          >
            <FormattedMessage
              id="confirmation_content_info"
              values={{ ...richText }}
            />
          </div>
          <ul className="list-style2">
            <span
              data-test-id="login-confirmation-list"
              className="bullet-color"
            >
              <FormattedMessage
                id="moreinfo_confirmation_link1"
                values={{ ...richText }}
              />
            </span>
          </ul>
        </section>
        <section
          data-test-id="login-confirmation-scroll-to-top"
          className="grid-container"
        >
          <a onClick={scrollToTop} href="">
            {' '}
            <FormattedMessage
              id="scroll_to_top_text"
              values={{ ...richText }}
            />{' '}
          </a>
        </section>
      </div>
    </>
  );
};

export default Login;
