import React from 'react';
import { useIntl } from 'react-intl';
import { EmailTemplate } from './EmailTemplate';

export const Email = (swa) => {
  const FetchEmailTemplate = (params) => {
    const { formatMessage } = useIntl();
    const href = formatMessage({
      id: 'redirect_email',
    });
    return <EmailTemplate href={href} swa={params.swa} />;
  };

  return FetchEmailTemplate(swa);
};
