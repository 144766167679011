import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';
import './enrollment.scss';
import {
  emailUpdatedLocation,
  getBarcodeImage,
  getAddressList,
  getCSRFToken,
  getServicesConfig,
} from '../../../service/addressSearch';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import AddressList from '../address-list/AddressList';
import AddressSearchForm from '../address-search/AddressSearchForm';
import { USPSDescription } from '../../usps-description/usps-description';
import Button from '../../../common/Button';
import {
  useAnalyticsEventTracker,
  scrollToTop,
  numberToWord,
} from '../../../utils/utils';
import baseConfig from '../../../utils/config';
import states from '../../../i18n/states';
import StepActiveStatus from '../../../common/Stepper/StepActiveStatus';

function Enrollment(props) {
  const { activeStep, steps, setActiveStep, setLoading } = props;
  const gaEventTracker = useAnalyticsEventTracker('Enrollment');
  const richText = {
    b: (chunks) => <b>{chunks}</b>,
  };

  const postOffices = useSelector((state) => state.enrollment.postOffices);
  const alternatePostOffices = useSelector(
    (state) => state.enrollment.alternatePostOffices
  );

  const enrollmentCode = useSelector(
    (state) => state.enrollment.enrollmentCode
  );
  const {
    streetAddress1,
    streetAddress2,
    city,
    state,
    zipcode,
    firstName,
    lastName,
    email,
  } = useSelector((state) => state.addressSearch);
  const alterAddressFormData = useSelector(
    (state) => state.alternateAddressSearch
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const swa = useParams().state;
  const swa_xid = searchParams.get('swa_xid');
  const lang_cd = searchParams.get('lang_cd');
  moment.locale(lang_cd || 'en');

  const enrollment_code = searchParams.get('enrollment_code');
  const [barcodeImage, setBarcodeImage] = useState('');
  const myAlternateStreetAddress1 = useSelector(
    (state) => state.alternateAddressSearch.streetAddress1
  );
  const myAlternateStreetAddress2 = useSelector(
    (state) => state.alternateAddressSearch.streetAddress2
  );
  const myAlternateCity = useSelector(
    (state) => state.alternateAddressSearch.city
  );
  const myAlternateState = useSelector(
    (state) => state.alternateAddressSearch.state
  );
  const myAlternateZipcode = useSelector(
    (state) => state.alternateAddressSearch.zipcode
  );

  const myStreetAddress1 =
    streetAddress1 || sessionStorage.getItem('streetAddress1');
  const myStreetAddress2 =
    streetAddress2 === ''
      ? ' '
      : streetAddress2 || sessionStorage.getItem('streetAddress2');
  const myCity = city || sessionStorage.getItem('city');
  const myState = sessionStorage.getItem('state') || state;
  const myZipcode = zipcode || sessionStorage.getItem('zipcode');
  const myFirstName = firstName || sessionStorage.getItem('firstname');
  const myLastName = lastName || sessionStorage.getItem('lastname');
  const myEmail = email || sessionStorage.getItem('email');
  const validDuration = sessionStorage.getItem('validDuration');
  const [showAddressSearchForm, setShowAddressSearchForm] = useState(false);
  const [hybrid, setHybrid] = useState(false);
  const [token, setToken] = useState(null);
  const HYBRID_CODE = 16;
  const myBarcode = useMemo(() => {
    return (
      enrollmentCode || sessionStorage.getItem('barcode') || enrollment_code
    );
  }, [enrollmentCode, enrollment_code]);
  const hasFetchedBarcodeRef = useRef(false);

  useEffect(() => {
    const showAlternateAddress = sessionStorage.getItem(
      'allow_alternate_address'
    );
    if (showAlternateAddress === 'true') {
      setShowAddressSearchForm(true);
    } else {
      setShowAddressSearchForm(false);
    }
  }, []);

  const [
    alternateAddressSentEmailMessage,
    setAlternateAddressSentEmailMessage,
  ] = useState('');
  let initialPostOffice;

  if (postOffices.length !== 0) {
    initialPostOffice = postOffices;
  } else if (sessionStorage.getItem('usps')) {
    initialPostOffice = JSON.parse(sessionStorage.getItem('usps'));
  } else {
    initialPostOffice = [];
  }

  const [myPostOffice, setMyPostOffice] = useState(initialPostOffice);

  const alternatePostOffice =
    alternatePostOffices?.length !== 0
      ? alternatePostOffices
      : JSON.parse(sessionStorage.getItem('alternateAddress'));

  useEffect(() => {
    if (hasFetchedBarcodeRef.current) return;
    window.scrollTo(0, 0);
    setActiveStep(steps[1]);
    sessionStorage.setItem('usps', JSON.stringify(myPostOffice));
    sessionStorage.setItem('streetAddress1', myStreetAddress1);
    sessionStorage.setItem('streetAddress2', myStreetAddress2);
    sessionStorage.setItem('city', myCity);
    sessionStorage.setItem('state', myState);
    sessionStorage.setItem('zipcode', myZipcode);
    sessionStorage.setItem('firstname', myFirstName);
    sessionStorage.setItem('lastname', myLastName);
    sessionStorage.setItem('email', myEmail);
    const mandatoryAttr = [
      'zipcode',
      'streetAddress1',
      'firstname',
      'lastname',
      'email',
      'city',
    ];

    if (!enrollment_code) {
      mandatoryAttr.some((d) => {
        if (
          !sessionStorage.getItem(d) ||
          sessionStorage.getItem(d) === 'null'
        ) {
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'WebAddressIncomplete' })
          );
          gaEventTracker(`Null values on the enrollment page`);
          navigate(
            `/error/${swa}/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
            {
              state: { type: 'WebAddressIncomplete' },
            }
          );
          return true;
        }
        return false;
      });
      setLoading(true);
    }

    const initialize = async () => {
      const fetchedToken = await getCSRFToken();
      setToken(fetchedToken);

      await getServicesConfig(swa)
        .then((response) => {
          if (response.data.state_usage === HYBRID_CODE) {
            setLoading(true);
            sessionStorage.setItem(
              'validDuration',
              response.data.identity_providers.usps_ipp.valid_duration
            );
            getAddressList(swa, swa_xid, null, enrollment_code, token)
              .then((response) => {
                sessionStorage.setItem(
                  'usps',
                  JSON.stringify(response.data.postOffices)
                );
                setMyPostOffice(response.data.postOffices);
                setHybrid(true);
                setLoading(false);
              })
              .catch((error) => {
                if (error?.response?.status === 409) {
                  sessionStorage.setItem(
                    'state_errorType',
                    JSON.stringify({ type: 'WebAddressIncomplete' })
                  );
                  navigate(
                    `/error/${swa}/?swa_xid=${swa_xid}&lang_cd=${
                      lang_cd || baseConfig.defaultLanguage
                    }`,
                    { state: { type: 'WebAddressIncomplete' } }
                  );
                } else {
                  sessionStorage.setItem(
                    'state_errorType',
                    JSON.stringify({ type: 'HitaWall' })
                  );
                  navigate(
                    `/error/${swa}/?swa_xid=${swa_xid}&lang_cd=${
                      lang_cd || baseConfig.defaultLanguage
                    }`,
                    { state: { type: 'HitaWall' } }
                  );
                }
              });
          }
        })
        .catch(() => {
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'HitaWall' })
          );
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            { state: { type: 'HitaWall' } }
          );
        });

      await getBarcodeImage(myBarcode, swa, swa_xid)
        .then((response) =>
          'data:image/png;base64, '.concat(
            Buffer.from(response.data, 'binary').toString('base64')
          )
        )
        .then((response) => {
          if (!enrollment_code) {
            sessionStorage.setItem('barcode', myBarcode);
          }
          setBarcodeImage(response);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          navigate(
            `/error/${swa}/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
            {
              state: { type: 'WebAddressIncomplete' },
            }
          );
        });
    };

    initialize();
  }, [myBarcode, swa, swa_xid, setActiveStep, steps]);

  const url = `start/${swa}/?swa_xid=${swa_xid}&lang_cd=${lang_cd || 'en'}`;

  const getAlternateEmailUpdatedLocationPayload = () => {
    const { streetAddress1, streetAddress2, city, state, zipcode } =
      alterAddressFormData;

    const emailUpdatedLocationPayload = {
      first_name: myFirstName,
      last_name: myLastName,
      contact_info: {
        street_address: `${streetAddress1}`.trim(),
        city: city.trim(),
        state,
        zip_code: zipcode,
        email: myEmail,
      },
    };
    if (zipcode.includes('-')) {
      emailUpdatedLocationPayload.contact_info.zip_code = zipcode.split('-')[0];
      emailUpdatedLocationPayload.contact_info.zip_ext = zipcode.split('-')[1];
    }
    if (streetAddress2) {
      emailUpdatedLocationPayload.contact_info.street_address_2 =
        `${streetAddress2}`.trim();
    }
    return emailUpdatedLocationPayload;
  };

  async function sendAlternateAddressEmail() {
    try {
      await emailUpdatedLocation(
        getAlternateEmailUpdatedLocationPayload(),
        myBarcode,
        swa,
        swa_xid
      );
      setAlternateAddressSentEmailMessage(
        'alternate_address_email_sent_message'
      );
    } catch (error) {
      setAlternateAddressSentEmailMessage(
        'alternate_address_email_sent_error_message'
      );
    }
  }

  let uspsFacility;
  if (!hybrid) {
    uspsFacility = <FormattedMessage id="USPS_facilities_located_around" />;
  } else if (myPostOffice.length !== 0) {
    uspsFacility = <FormattedMessage id="USPS_facilities_hybrid" />;
  } else {
    uspsFacility = null;
  }

  return (
    <div
      className="enrollment"
      data-test-id="VERIFICATION-HEADER"
      id="mainContent"
    >
      <section id="main-content" className="main-content">
        <div className="enrollment-heading">
          {!hybrid && <StepActiveStatus activeStep={activeStep} />}
          <span className="subheading-text" data-test-id="ENROLLMENTHEADING">
            <FormattedMessage
              id="enrollment_heading_text"
              values={{ sup: (data) => <sup>{data}</sup> }}
            />{' '}
            {moment().add(validDuration, 'days').format('MMMM DD, YYYY')}
          </span>
        </div>
        <div
          className="enrollment-description"
          data-test-id="enrollment-description1"
        >
          <p>
            <FormattedMessage
              id="enrollment_description_para1"
              values={{ sup: (data) => <sup>{data}</sup>, ...richText }}
            />
          </p>
        </div>
        <div className="barcode-container" data-test-id="ENROLLMENTCODE-LABLE">
          <img src={barcodeImage} alt={`enrollmentcode ${myBarcode}`} />
        </div>
        <div className="enrollment-code" data-test-id="ENROLLMENTCODE">
          <FormattedMessage
            id="enrollment_code_bold_text"
            values={{ ...richText }}
          />
          <label className="code-number">{myBarcode}</label>
        </div>
        <div
          className="enrollment-description"
          data-test-id="enrollment-description2"
        >
          <p>
            <span className="bold-text">
              <FormattedMessage id={`enrollment_description_para2_boldText`} />
              {numberToWord(lang_cd, validDuration)} {}({validDuration})
              <FormattedMessage id={`enrollment_description_para2_boldText1`} />
            </span>{' '}
            <FormattedMessage id={`enrollment_description_para2`} />
          </p>
        </div>
        <div className="enrollment-btn-container">
          <Button
            type="primary"
            id="print-this-page-button"
            onClick={() => {
              gaEventTracker(`${swa}-Print this page`);
              window.print();
            }}
            data-test-id="print-this-page-button"
            data-testid="print-this-page-button"
          >
            <FormattedMessage id="print_this_pageBtn_text" />
          </Button>
          {(swa in states
            ? states[swa]?.chooseDifferentVerificationButton_USPS
            : true) &&
            !hybrid && (
              <Button type="secondary" data-test-id="CHOOSEDIFFVERMETHOD">
                <a
                  href={url}
                  onClick={() =>
                    gaEventTracker(
                      `${swa}-Choose different method button in enrollment page`
                    )
                  }
                >
                  <FormattedMessage id="enrollment_different_verification_method_link" />
                </a>
              </Button>
            )}
        </div>
        {!hybrid && <hr className="hr2" />}
        {!hybrid && (
          <div className="user-details-wrapper">
            <div className="id-wrapper">
              <div className="field-wrapper">
                <h3
                  className="id-details"
                  data-test-id="id-details-personal-info"
                >
                  <FormattedMessage id="enrollment_personal_information_text" />
                </h3>
                <div
                  id="edit-address"
                  className="edit-wrapper desktop"
                  data-test-id="edit-address"
                  onClick={() => {
                    gaEventTracker(`${swa}-Edit Identification Details`);
                    setActiveStep(steps[0]);
                    navigate(
                      `/search/${swa}/?swa_xid=${swa_xid}&enrollment_code=${myBarcode}&lang_cd=${
                        lang_cd || 'en'
                      }`
                    );
                  }}
                >
                  <EditIcon aria-label="Edit Address " />
                  <button
                    className="edit-id-details"
                    data-test-id="ENROLLMENT_EDIT_BUTTON"
                  >
                    <FormattedMessage id="enrollment_id_details_editicon_text" />
                  </button>
                </div>
              </div>
              <div className="name-field-wrapper">
                <FormattedMessage
                  id="enrollment_id_details_name_bold_text"
                  values={{ ...richText }}
                />
                <span
                  className="fullname-wrapper-input"
                  data-test-id="fullname-wrapper-input"
                >{`${myFirstName} ${myLastName}`}</span>
              </div>
              <div className="email-field-wrapper">
                <FormattedMessage
                  id="enrollment_id_details_email_bold_text"
                  values={{ ...richText }}
                />
                <span
                  className="email-wrapper-input"
                  data-test-id="email-wrapper-input"
                >
                  {myEmail}
                </span>
              </div>
              <div className="legal-address-wrapper">
                <span className="address-wrapper">
                  <FormattedMessage
                    id="enrollment_id_details_address_bold_text"
                    values={{ ...richText }}
                  />
                </span>
                <span
                  className="field-wrapper-input"
                  data-test-id="field-wrapper-input"
                >
                  {' '}
                  {myStreetAddress1} {myStreetAddress2}, {myCity}, {myState}{' '}
                  {myZipcode}
                </span>
              </div>
            </div>
          </div>
        )}
        {!hybrid && (
          <div
            id="edit-address"
            className="edit-wrapper mobile"
            data-test-id="edit-address-mobile"
            onClick={() => {
              setActiveStep(steps[0]);
              navigate(
                `/search/${swa}/?swa_xid=${swa_xid}&enrollment_code=${myBarcode}&lang_cd=${
                  lang_cd || 'en'
                }`
              );
            }}
          >
            <EditIcon aria-label="Edit Address " />
            <button className="edit-id-details">
              <FormattedMessage id="enrollment_id_details_editicon_text" />
            </button>
          </div>
        )}
        {!hybrid && <hr className="hr2" />}
        <div className="address-top-section">
          <div>
            <span className="uspc-heading" data-test-id="POSTOFFICES">
              {uspsFacility}
            </span>
            {!hybrid && (
              <span className="uspc-adress" data-test-id="uspc-adress">
                {myStreetAddress1} {myStreetAddress2}, {myCity}, {myState}{' '}
                {myZipcode}
              </span>
            )}
          </div>
          <AddressList
            postOffices={myPostOffice}
            heading={'USPS Location'}
            lang_cd={lang_cd}
          />
        </div>
        {showAddressSearchForm || hybrid ? (
          <>
            <hr className="hr2" />
            <AddressSearchForm
              setLoading={setLoading}
              myPostOffice={myPostOffice}
              hybrid={hybrid}
            />
          </>
        ) : null}
        {alternatePostOffice && alternatePostOffice?.length > 0 && (
          <div>
            <div className="uspc-heading" style={{ fontSize: '1rem' }}>
              <FormattedMessage id="USPS_facilities_located_around_count_text1" />
              {alternatePostOffice?.length}
              <FormattedMessage id="USPS_facilities_located_around_count_text2" />
              <span className="uspc-adress" data-test-id="uspc-adress">
                {myAlternateStreetAddress1} {myAlternateStreetAddress2},{' '}
                {myAlternateCity}, {myAlternateState} {myAlternateZipcode}
              </span>
            </div>
            <div className="address-top-section">
              <AddressList
                postOffices={alternatePostOffice}
                heading={'USPS Location'}
              />
              <Button
                type="primary"
                id="send-alternateAddress-email-button"
                data-test-id="send-alternateAddress-email-button"
                style={{ marginTop: '1.25rem' }}
                onClick={sendAlternateAddressEmail}
              >
                <FormattedMessage id="alternate_address_email_button" />
              </Button>
              <p>
                {alternateAddressSentEmailMessage && (
                  <FormattedMessage
                    id={alternateAddressSentEmailMessage}
                    values={{ ...richText }}
                  />
                )}
              </p>
            </div>
          </div>
        )}
        {alternatePostOffice?.length === 0 && (
          <>
            <h2 style={{ fontWeight: 'normal' }}>
              <span
                data-test-id="alternate-address-NoPostOffice-header-text"
                className="subheading-text"
              >
                <FormattedMessage id="alternate_address_noPostOffice_header_message" />
              </span>
            </h2>
            <div data-test-id="alternate-address-NoPostOffice-para1-text">
              <p>
                <FormattedMessage id="alternate_address_noPostOffice_para1_message" />
              </p>
            </div>
            <div data-test-id="alternate-address-NoPostOffice-para2-text">
              <p>
                <FormattedMessage id="alternate_address_noPostOffice_para2_message" />
              </p>
            </div>
            <br />
          </>
        )}
        <hr className="hr2" />
        <USPSDescription swa={swa} />
      </section>
      <br />
      <br />
      <div className="return-top">
        <a onClick={scrollToTop} href="">
          {' '}
          <FormattedMessage
            id="scroll_to_top_text"
            values={{ ...richText }}
          />{' '}
        </a>
      </div>
      <br />
    </div>
  );
}

export default Enrollment;
