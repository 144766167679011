import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { richText } from '../../../i18n/states';

function FormInput({ errors, labelKey, required, children, accessor }) {
  const { formatMessage } = useIntl();

  const renderErrorMessage = (msgKey, fieldIdentifier) => {
    const keys = msgKey.split(',');
    let errorMsg;
    const uniqueTestId = `ERRORXPATH_${fieldIdentifier}`;

    if (keys.length > 1) {
      errorMsg = (
        <>
          <div className="error-text error-bold-font">
            <FormattedMessage id={keys[0]} />
          </div>
          <div className="error-text" data-test-id={`${uniqueTestId}`}>
            <FormattedMessage id={keys[1]} values={{ ...richText }} />
          </div>
        </>
      );
    } else {
      errorMsg = (
        <div
          className="error-text error-bold-font"
          data-test-id={`${uniqueTestId}`}
        >
          <FormattedMessage id={keys[0]} values={{ ...richText }} />
        </div>
      );
    }

    return errorMsg;
  };

  return (
    <div
      className={
        errors && errors[accessor] && errors[accessor].message
          ? 'error-field-padding field'
          : 'field'
      }
    >
      <label
        htmlFor={formatMessage({
          id: labelKey,
        })}
      >
        <FormattedMessage id={labelKey} />
        {required && <div className="required-field">* </div>}
      </label>

      {errors[accessor]?.message &&
        renderErrorMessage(errors[accessor]?.message, accessor)}

      {children}
    </div>
  );
}

export default FormInput;
