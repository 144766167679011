import React from 'react';
import { Modal } from '@mui/material';
import './language-modal.scss';
import { FormattedMessage } from 'react-intl';
import { richText } from '../../i18n/states';
import { updateQueryStringParameter } from '../../utils/utils';
import Button from '../../common/Button';

function LanguageModal({
  showLanguageModal,
  language,
  setShowLanguageModal,
  setLanguage,
}) {
  const onContinueButtonClick = async () => {
    const langCd = language === 1 ? 'en' : language;
    const url = updateQueryStringParameter(
      window.location.href,
      'lang_cd',
      langCd
    );
    window.location.href = url;
    setShowLanguageModal(false);
  };

  return (
    <Modal open={showLanguageModal} className="language-modal">
      <div
        className="language-modal-container"
        data-test-id="language-modal-container"
      >
        <div className="language-modal-header">
          <p>
            <FormattedMessage
              id="language_modal_header"
              values={{ ...richText }}
            />
          </p>
        </div>
        <div className="language-modal-paragraph">
          <span>
            <p>
              <FormattedMessage
                id="language_modal_para1"
                values={{ ...richText }}
              />
              <br />
            </p>
          </span>
          <span>
            <p>
              <FormattedMessage
                id="language_modal_para2"
                values={{ ...richText }}
              />
              <br />
              <br />
            </p>
          </span>
        </div>
        <div className="language-modal-btn-container">
          <Button
            type="secondary"
            onClick={() => {
              setShowLanguageModal(false);
              setLanguage(1);
            }}
            data-testid="back-btn"
          >
            <FormattedMessage
              id="language_back_button"
              values={{ ...richText }}
            />
          </Button>
          <Button
            type="primary"
            onClick={() => onContinueButtonClick()}
            data-testid="continue-btn"
          >
            <FormattedMessage
              id="language_continue_button"
              values={{ ...richText }}
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default LanguageModal;
