import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { scrollToTop } from '../../utils/utils';
import './Workforce.scss';
import { richText } from '../../i18n/states';

const Workforce = () => {
  const { formatMessage } = useIntl();
  const href_link1 = formatMessage({
    id: 'href_link1',
  });
  const href_link2 = formatMessage({
    id: 'href_link2',
  });
  const href_link3 = formatMessage({
    id: 'href_link3',
  });
  const para1_link = formatMessage({
    id: 'para1_link',
  });
  const para3_link = formatMessage({
    id: 'para3_link',
  });

  return (
    <div className="workforce-page" id="mainContent">
      <h1
        className="grid-container grid-header"
        data-test-id="WORKFORCE_HEADER"
      >
        <FormattedMessage id="workforce_heading_text" />
      </h1>
      <hr />
      <div className="grid-container main-section">
        <section id="main-content">
          <h2 className="sub-heading" data-test-id="sub-heading">
            <FormattedMessage
              id="workforce_appointment_text"
              values={{ ...richText }}
            />
          </h2>
          <p data-test-id="workforce-para1">
            <FormattedMessage id="workforce_para1" />
            <a href={para1_link}>
              <FormattedMessage id="para1_link_text" />
            </a>
          </p>
          <p data-test-id="workforce-para2">
            <FormattedMessage id="workfore_para2" values={{ ...richText }} />
          </p>
          <p data-test-id="workforce-para3">
            <FormattedMessage id="workfore_para3" values={{ ...richText }} />
            <a href={para3_link}>
              <FormattedMessage id="para3_link_text" />
            </a>
          </p>
        </section>
        <section>
          <h2 className="more-information" data-test-id="more-information">
            <FormattedMessage id="workforce_more_info_text" />
          </h2>
          <ul>
            <a
              href={href_link1}
              className="link-decoration"
              data-test-id="moreinfo_link1"
            >
              <FormattedMessage
                id="workforce_moreinfo_link1"
                values={{ ...richText }}
              />
            </a>
            <a
              href={href_link2}
              className="link-decoration"
              data-test-id="moreinfo_link2"
            >
              <FormattedMessage
                id="workforce_moreinfo_link2"
                values={{ ...richText }}
              />
            </a>
            <a
              href={href_link3}
              className="link-decoration"
              data-test-id="moreinfo_link3"
            >
              <FormattedMessage
                id="workforce_moreinfo_link3"
                values={{ ...richText }}
              />
            </a>
          </ul>
        </section>
        <div className="scroll-top-height">
          <a onClick={scrollToTop} href="" data-testid="test">
            {' '}
            <FormattedMessage id="scroll_to_top_text" />{' '}
          </a>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Workforce;
