import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  postOffices: [],
  alternatePostOffices: null,
};

const enrollmentReducer = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    setPostOffices: (state, action) => ({
      ...state,
      postOffices: action.payload,
    }),
    setAlternatePostOffices: (state, action) => ({
      ...state,
      alternatePostOffices: action.payload,
    }),
    setEnrollmentCode: (state, action) => ({
      ...state,
      enrollmentCode: action.payload,
    }),
  },
});

export const { setPostOffices, setEnrollmentCode, setAlternatePostOffices } =
  enrollmentReducer.actions;

export default enrollmentReducer.reducer;
