import React from 'react';
import { FormattedMessage } from 'react-intl';
import { richText } from '../../../i18n/states';

export const EmailTemplate = (params) => {
  return (
    <a href={params.href} rel="noopener noreferrer" target="_blank">
      <span className="email-link">
        <FormattedMessage id={`usps_state_email`} values={{ ...richText }} />
      </span>
    </a>
  );
};
