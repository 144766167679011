import axios from 'axios';
import axiosRetry from 'axios-retry';
import baseConfig from './config';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const eventTarget = new EventTarget();
const showLoader = new Event('showLoader');
const hideLoader = new Event('hideLoader');
const retryCountMapper = {};
axios.interceptors.request.use(
  (config) => {
    const conf = config;
    conf.headers['X-Transactionid'] = sessionStorage.getItem('transactionId');
    return conf;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (retryCountMapper[error.config.url] === +baseConfig.retries) {
      eventTarget.dispatchEvent(hideLoader);
    }
    return Promise.reject(error);
  }
);

axiosRetry(axios, {
  retries: baseConfig.retries,
  onRetry: (retryCount, _error, requestConfig) => {
    retryCountMapper[requestConfig.url] = retryCount;
    if (retryCount <= baseConfig.retries) {
      eventTarget.dispatchEvent(showLoader);
    }
  },
  retryDelay: (retryCount) => {
    return retryCount * baseConfig.retryInterval;
  },
  retryCondition: (error) => {
    return error.message === 'Network Error' || error.response.status >= 500;
  },
});

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  if (!params.lang_cd) {
    params.lang_cd = baseConfig.defaultLanguage;
  }
  return params;
};

export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.includes('?') ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }
  return `${uri}${separator}${key}=${value}`;
};

export const validateSwaXid = (swa, swa_xid, validateUnique) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        method: 'get',
        url: `${BASE_URL}v1/${swa}/xid/${swa_xid}`,
        withCredentials: true,
        params: { validate_unique: validateUnique },
      };
      const response = await axios(config);
      if (response.data.is_valid) {
        resolve(true);
      } else {
        reject(false);
        // resolve(true)
      }
      if (response.data.configuration) {
        sessionStorage.setItem(
          'biometric_enabled',
          response.data.configuration.biometric_comparison
        );
      }
    } catch (error) {
      // resolve(true)

      if (error.response?.status === 400 || error.response?.status === 409) {
        reject('WebAddressIncomplete');
      } else if (error.response?.status === 404) {
        reject('WebAddressInvalid');
      } else {
        reject('HitaWall');
      }
    }
  });
};

export const useAnalyticsEventTracker = (category = 'Blog category') => {
  const eventTracker = (action = 'test action', label = 'test label') => {
    window.sendToGA(action, { category, action, label });
  };
  return eventTracker;
};

export const scrollToTop = (e) => {
  e.preventDefault();
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const scrollToBottom = (e) => {
  e.preventDefault();
  window.scrollTo({
    top: document.documentElement.scrollHeight * 0.4,
    behavior: 'smooth',
  });
};

export const scrollToMainContent = () => {
  const target = document.getElementById('mainContent');
  target.scrollIntoView({ behavior: 'smooth' });
};

export const numberToWord = (lang_cd, validDuration) => {
  if (lang_cd === 'en') {
    switch (validDuration) {
      case '1':
        return 'one';
      case '2':
        return 'two';
      case '3':
        return 'three';
      case '4':
        return 'four';
      case '5':
        return 'five';
      case '6':
        return 'six';
      default:
        return 'seven';
    }
  }

  if (lang_cd === 'es') {
    switch (validDuration) {
      case '1':
        return 'una';
      case '2':
        return 'dos';
      case '3':
        return 'tres';
      case '4':
        return 'cuatro';
      case '5':
        return 'cinco';
      case '6':
        return 'seis';
      default:
        return 'siete';
    }
  }

  return '';
};
