import React from 'react';
import './Button.scss';

function Button(props) {
  const { type, children } = props;
  return (
    <button {...props} className={`basic ${type || 'primary'}`}>
      {children}
    </button>
  );
}

export default Button;
