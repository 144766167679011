import React from 'react';
import { FormattedMessage } from 'react-intl';
import './index.scss';

const PageTitle = ({ path }) => {
  let headerJsx = null;
  if (path === 'search') {
    headerJsx = (
      <div>
        <div className="address-header" data-test-id="USPS_REQUEST_HEADER">
          <h1>
            <FormattedMessage
              id="header_text"
              values={{ sup: (data) => <sup>{data}</sup> }}
            />{' '}
            <sup>
              <FormattedMessage id="header_text_sup" />
            </sup>
          </h1>
        </div>
        <hr />
      </div>
    );
  } else if (path === 'enrollment') {
    headerJsx = (
      <div>
        <div className="enrollment-verification-heading">
          <h1 data-test-id="enrollment-verification-heading">
            <FormattedMessage id="enrollment_component_heading" />
          </h1>
        </div>
        <hr />
      </div>
    );
  }
  return <>{headerJsx}</>;
};

export default PageTitle;
