import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const notWorkingDay = 'Closed';

const formatHours = (hoursData, lang_cd) => {
  const hours = JSON.parse(hoursData.replaceAll("'", '"'));
  const transformResponse = (response) => {
    const transformedData = {
      Monday: '',
      Tuesday: '',
      Wednesday: '',
      Thursday: '',
      Friday: '',
      Saturday: '',
      Sunday: '',
    };

    response.forEach((item) => {
      if (item.weekdayHours) {
        Object.keys(transformedData).forEach((day) => {
          if (
            ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(
              day
            )
          ) {
            transformedData[day] = item.weekdayHours;
          }
        });
      }
      if (item.saturdayHours) {
        if (lang_cd === 'es') {
          transformedData.Saturday = (
            <FormattedMessage id="address_list_office_hours" />
          );
        } else {
          transformedData.Saturday = item.saturdayHours;
        }
      }
      if (item.sundayHours) {
        if (lang_cd === 'es') {
          transformedData.Sunday = (
            <FormattedMessage id="address_list_office_hours" />
          );
        } else {
          transformedData.Sunday = item.sundayHours;
        }
      }
    });

    return transformedData;
  };

  const transformedData = transformResponse(hours);
  const now = moment();
  const daysOfWeek = moment.weekdays();

  const totalDays = 7;
  const todayIndex = now.day();
  const tomorrowIndex = (todayIndex + 1) % totalDays;

  const getHoursForDay = (day) => {
    const dayName = daysOfWeek[day];
    return transformedData[dayName] || notWorkingDay;
  };

  const todayHours = getHoursForDay(todayIndex);
  const tomorrowHours = getHoursForDay(tomorrowIndex);

  const nextDaysHours = [];
  let i = 1;
  while (i < 6) {
    const dayIndex = (tomorrowIndex + i) % totalDays;
    const dayName = daysOfWeek[dayIndex];
    nextDaysHours.push({
      day: dayName,
      hours: getHoursForDay(dayIndex),
    });
    i += 1;
  }

  return {
    todayHours,
    tomorrowHours,
    nextDaysHours,
  };
};

const HoursDisplay = ({
  hoursData,
  isToday = false,
  lang_cd,
  addressIndex,
}) => {
  const { todayHours, tomorrowHours, nextDaysHours } = formatHours(
    hoursData,
    lang_cd
  );
  return (
    <div>
      {isToday ? (
        <div>
          {todayHours === notWorkingDay ? (
            <FormattedMessage id="address_list_office_hours" />
          ) : (
            <>
              <FormattedMessage id="address_list_today_office_hours" />{' '}
              {todayHours.split(' - ')[1]}
            </>
          )}
        </div>
      ) : (
        <>
          <Stack direction={'row'}>
            <Typography
              as="p"
              sx={{ flex: '1 1 12ch' }}
              data-testid={`address-${addressIndex}-tomorrow`}
            >
              <FormattedMessage id="address_list_tomorrow_label" />
            </Typography>
            <Typography as="p" sx={{ flex: '1 1 18ch' }}>
              {tomorrowHours}
            </Typography>
          </Stack>
          <div>
            {nextDaysHours.map(({ day, hours }, dayIndex) => (
              <Stack direction={'row'} justifyContent={'flex-start'} key={day}>
                <Typography
                  as="p"
                  sx={{ flex: '1 1 12ch' }}
                  data-testid={`address-${addressIndex}-${dayIndex}`}
                >
                  <FormattedMessage id={day} />
                </Typography>
                <Typography
                  as="p"
                  sx={{ flex: '1 1 18ch', width: '11.25em' }}
                  data-testid={`address-${addressIndex}-${dayIndex}-time`}
                >
                  {hours}
                </Typography>
              </Stack>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HoursDisplay;
