import { combineReducers } from '@reduxjs/toolkit';
import addressSearchReducer from './addressSearchReducer';
import enrollmentReducer from './enrollmentReducer';
import alternateAddressSearchReducer from './alternateAddressSearchReducer';

const rootReducer = combineReducers({
  addressSearch: addressSearchReducer,
  enrollment: enrollmentReducer,
  alternateAddressSearch: alternateAddressSearchReducer,
});

export default rootReducer;
