import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import './Error.scss';
import axios from 'axios';
import {
  useAnalyticsEventTracker,
  BASE_URL,
  scrollToTop,
} from '../../utils/utils';
import states, { richText } from '../../i18n/states';
import baseConfig from '../../utils/config';
import Button from '../../common/Button';

import { ReactComponent as EditIcon } from '../../icons/edit.svg';

const Error = () => {
  const gaEventTracker = useAnalyticsEventTracker('Error Page');
  const [searchParams, setSearchParams] = useSearchParams();
  const [unSuccessfulLogin, setUnsuccessfulLogin] = useState(false);
  let { state } = useLocation();
  const navigate = useNavigate();
  let swa = useParams().state;
  if (!swa) {
    swa = location.pathname.split('/')[2];
  }

  let swa_xid = searchParams.get('swa_xid');
  const lang_cd = searchParams.get('lang_cd');
  const error_type = searchParams.get('error_type');
  const ial_type = searchParams.get('ial');
  const redirect_from = searchParams.get('redirect_from');
  const error_state = searchParams.get('error_state');
  const { formatMessage } = useIntl();
  const loginHere = 'loginLink';
  const forgotPwd = 'forgotPwd';

  const handleRedirect = async (source) => {
    let path = '';
    let sourceClick = '';

    switch (source) {
      case loginHere:
        sourceClick = 'Login Button Clicked';
        path = `v1/logindotgov/authorization/${
          baseConfig.logindotgovIAL
        }?return_url=true&lang-cd=${lang_cd || baseConfig.defaultLanguage}`;
        break;
      case forgotPwd:
        sourceClick = 'Password Button Clicked';
        path = `v1/logindotgov/profile?request_type=password_reset&return_url=true&lang-cd=${
          lang_cd || baseConfig.defaultLanguage
        }`;
        break;
      default:
        return;
    }
    const config = {
      method: 'GET',
      url: `${BASE_URL}${path}`,
      headers: {
        swa: swa,
        'swa-xid': swa_xid,
      },
    };
    try {
      gaEventTracker(`${swa}-${sourceClick}`);
      const response = await axios(config);
      window.location.href = response.data.url;
    } catch (error) {
      sessionStorage.clear();
      sessionStorage.setItem(
        'state_errorType',
        JSON.stringify({ type: 'HitaWall' })
      );
      gaEventTracker(
        `${swa}-Login.gov service unavailable in Login-Unsuccessfull page`
      );
      navigate(
        `/error/${swa}/?swa_xid=${swa_xid}&redirect_from=${'login'}&lang_cd=${
          lang_cd || baseConfig.defaultLanguage
        }`,
        { state: { type: 'HitaWall' } }
      );
    }
  };

  const login_link = formatMessage({
    id: 'login_link',
  });

  useEffect(() => {
    if (searchParams === null || lang_cd === null) {
      searchParams.set('lang_cd', 'en');
      setSearchParams(searchParams);
    }

    if (error_type) {
      setUnsuccessfulLogin(true);
    }
  }, [searchParams, setSearchParams]);

  if (!swa_xid && localStorage.getItem('swa_xid')) {
    swa_xid = localStorage.getItem('swa_xid');
  }

  const url = `start/${swa}/?swa_xid=${swa_xid}&lang_cd=${lang_cd || 'en'}`;
  const loginMsg = <FormattedMessage id="hitawall_login_subheading" />;
  const uspsMsg = <FormattedMessage id="hitawall_usps_subheading" />;
  const msg = redirect_from === 'login' ? loginMsg : uspsMsg;
  const loginHeading = <FormattedMessage id="hitawall_login_heading" />;
  const uspsHeading = <FormattedMessage id="hitawall_usps_heading" />;
  const msgHeading = redirect_from === 'login' ? loginHeading : uspsHeading;
  const myFirstName = sessionStorage.getItem('firstName');
  const myLastName = sessionStorage.getItem('lastName');
  const myStreetAddress1 = sessionStorage.getItem('streetAddress1');
  const myStreetAddress2 = sessionStorage.getItem('streetAddress2');
  const myCity = sessionStorage.getItem('city');
  const myZipcode = sessionStorage.getItem('zipcode');
  const myEmail = sessionStorage.getItem('email');
  const myState = sessionStorage.getItem('state');
  const hybridCode = sessionStorage.getItem('state_usage_code');

  const ErrorDetails = {
    LoginUnsuccessful: {
      title: 'Login Unsuccessful',
      errorMessage: (
        <div className="usps-page-wrapper">
          <div data-test-id="ERROR_PAGE_HEADER">
            <h1 className="grid-container header">
              <FormattedMessage id="error_invalid_title" />
            </h1>
          </div>
          <hr />
          <div className="grid-container">
            <p className="text-align">
              {!ial_type && (
                <div data-test-id="login-error-2">
                  <span
                    className="error-page-subheading"
                    data-test-id="ERROR_MESSAGE_SPAN_1"
                  >
                    <FormattedMessage
                      id="invalid_login2"
                      values={{ ...richText }}
                    />
                    <br />
                  </span>
                  <br />
                  <span
                    className="first-para"
                    data-test-id="ERROR_MESSAGE_SPAN_2"
                  >
                    <FormattedMessage
                      id="error_invalid_login2_para1"
                      values={{ ...richText }}
                    />
                    <br />
                  </span>
                  <ul>
                    <span
                      className="first-para"
                      data-test-id="ERROR_MESSAGE_SPAN_2"
                    >
                      <FormattedMessage
                        id="error_invalid_login2_para1_list"
                        values={{ ...richText }}
                      />
                      <br />
                    </span>
                  </ul>
                </div>
              )}
              {ial_type === '1' && (
                <div data-test-id="login-error-1">
                  <span
                    className="error-page-subheading"
                    data-test-id="ERROR_MESSAGE_SPAN_1"
                  >
                    <FormattedMessage
                      id="invalid_login1"
                      values={{ ...richText }}
                    />
                    <br />
                  </span>
                  <br />
                  <span
                    className="first-para"
                    data-test-id="ERROR_MESSAGE_SPAN_2"
                  >
                    <FormattedMessage
                      id="error_invalid_login1_para1"
                      values={{ ...richText }}
                    />
                    <br />
                  </span>
                  <ul>
                    <span
                      className="first-para"
                      data-test-id="ERROR_MESSAGE_SPAN_2"
                    >
                      <FormattedMessage
                        id="error_invalid_login1_para1_list"
                        values={{ ...richText }}
                      />
                      <br />
                    </span>
                  </ul>
                </div>
              )}
              {ial_type === '1.5' && (
                <div data-test-id="login-error-3">
                  <span
                    className="error-page-subheading"
                    data-test-id="ERROR_MESSAGE_SPAN_1"
                  >
                    <FormattedMessage
                      id="invalid_login3"
                      values={{ ...richText }}
                    />
                    <br />
                  </span>
                  <br />
                  <span
                    className="first-para"
                    data-test-id="ERROR_MESSAGE_SPAN_2"
                  >
                    <FormattedMessage
                      id="error_invalid_login3_para1"
                      values={{ ...richText }}
                    />
                    <br />
                  </span>
                </div>
              )}
              <span
                className="first-para"
                data-test-id="ERROR_MESSAGE_SPAN_2_SUBTEXT"
              >
                <FormattedMessage id="error_invalid_login_para1_subtext" />
                <br />
              </span>
              <br />
              <span className="error-page-button2">
                <Button type="primary">
                  <a
                    className="usps-link-text"
                    href={() => handleRedirect(loginHere)}
                    data-testid="login-link"
                    onClick={() => handleRedirect(loginHere)}
                  >
                    <FormattedMessage id="error_invalid_login_link" />
                  </a>
                </Button>
              </span>
              <span className="error-page-button2">
                <Button type="secondary">
                  <a
                    className="usps-link-text1"
                    href={() => handleRedirect(forgotPwd)}
                    data-testid="forgot-pwd"
                    onClick={() => handleRedirect(forgotPwd)}
                  >
                    <FormattedMessage id="error_invalid_login_forgot" />
                  </a>
                </Button>
              </span>
              {ial_type && (
                <>
                  <hr className="usps-mid-hr" />
                  <div
                    className="second-para"
                    data-test-id="ERROR_MESSAGE_SPAN_3"
                  >
                    {(swa in states
                      ? states[swa]?.showVerificationButton
                      : true) && (
                      <span className="error-page-button1">
                        <FormattedMessage
                          id="error_invalid_login_para2"
                          values={{ ...richText }}
                        />
                        <br />
                        <br />
                        <span className="error-page-button2">
                          <Button type="primary">
                            <a
                              className="usps-link-text"
                              href={url}
                              data-testid="enrollment-diff-verification"
                              onClick={() =>
                                gaEventTracker(
                                  `${swa}-Choose different button in error page`
                                )
                              }
                            >
                              <FormattedMessage id="different_verification_method_link" />
                            </a>
                          </Button>
                        </span>
                        <hr className="usps-mid-hr" />
                      </span>
                    )}
                  </div>

                  <span
                    className="third-para"
                    data-test-id="ERROR_MESSAGE_SPAN_4"
                  >
                    <h3 className="h3-more-info">
                      <FormattedMessage
                        id="more_info_text0"
                        values={{ ...richText }}
                      />
                    </h3>
                    <FormattedMessage
                      id="error_invalid_login_para3"
                      values={{ ...richText }}
                    />
                    <h3 className="h3-more-info">
                      <FormattedMessage
                        id="more_info_text1"
                        values={{ ...richText }}
                      />
                    </h3>
                    <FormattedMessage
                      id="error_invalid_login_para4"
                      values={{ ...richText }}
                    />
                    <FormattedMessage
                      id="error_invalid_login_para6"
                      values={{ ...richText }}
                    />
                  </span>
                  <span className="fourth-para">
                    <p className="contact-list">
                      <FormattedMessage id="login_gov_help_txt" />
                    </p>
                    <ul className="login-list-style">
                      <li>
                        <a href={login_link}>
                          <FormattedMessage id="login_link" />
                        </a>
                      </li>
                    </ul>
                    <h3 className="h3-more-info">
                      <FormattedMessage
                        id="more_info_text2"
                        values={{ ...richText }}
                      />
                    </h3>
                    <ul className="list-style">
                      <div className="bullet-color">
                        <FormattedMessage
                          id="error_invalid_login_moreinfo_link1"
                          values={{ ...richText }}
                        />
                      </div>
                      <FormattedMessage
                        id="error_invalid_login_moreinfo_link2"
                        values={{ ...richText }}
                      />
                    </ul>
                    <br />
                  </span>
                </>
              )}
              <FormattedMessage
                id="error_invalid_login_para7"
                values={{ ...richText }}
              />
              <span className="scroll-top-height">
                <a className="scroll-top-color" onClick={scrollToTop} href="">
                  {' '}
                  <FormattedMessage
                    id="scroll_to_top_text"
                    values={{ ...richText }}
                  />{' '}
                </a>
              </span>
            </p>
          </div>
          <br />
        </div>
      ),
    },
    Error_500: {
      title: '500 Error',
      errorMessage: (
        <div className="first-error-500-page-wrapper">
          <div
            className="grid-container header"
            data-test-id="ERROR_PAGE_HEADER"
          >
            <h1 className="error-500-page-title">
              <FormattedMessage id="error_500_title" />
            </h1>
          </div>
          <hr />
          <div className="grid-container">
            <p className="text-align">
              <span
                className="error-page-subheading"
                data-test-id="ERROR_MESSAGE_SPAN_1"
              >
                {sessionStorage.getItem('reason') === 'service_unavailable' ? (
                  <FormattedMessage id="error_service_unavailable_heading" />
                ) : (
                  <FormattedMessage id="error_500_heading" />
                )}
                <br />
              </span>
              <span className="first-para" data-test-id="ERROR_MESSAGE_SPAN_2">
                <FormattedMessage
                  id="error_500_para1"
                  values={{ ...richText }}
                />
                <br />
              </span>
              <span className="second-para" data-test-id="ERROR_MESSAGE_SPAN_3">
                <br />
                <FormattedMessage
                  id="error_500_para2"
                  values={{ ...richText }}
                />
              </span>
              <h3 className="h3-more-info">
                <FormattedMessage
                  id="error_500_more_info_text1"
                  values={{ ...richText }}
                />
              </h3>
              <span className="third-para" data-test-id="ERROR_MESSAGE_SPAN_4">
                <FormattedMessage
                  id="error_500_para3"
                  values={{ ...richText }}
                />
              </span>
              <span className="fourth-para">
                <p className="contact-list">
                  <FormattedMessage id="login_gov_help_txt" />
                </p>
                <ul className="login-list-style">
                  <li>
                    <a href={login_link}>
                      <FormattedMessage id="login_link" />
                    </a>
                  </li>
                </ul>
                <h3 className="h3-more-info">
                  <FormattedMessage
                    id="error_500_more_info_text2"
                    values={{ ...richText }}
                  />
                </h3>
                <ul className="list-style">
                  <span className="bullet-color">
                    <FormattedMessage
                      id="moreinfo_link1_500"
                      values={{ ...richText }}
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      id="moreinfo_link2_500"
                      values={{ ...richText }}
                    />
                  </span>
                </ul>
                <br />
              </span>
              <br />
              <span className="scroll-top-height">
                <a className="scroll-top-color" onClick={scrollToTop} href="">
                  {' '}
                  <FormattedMessage id="scroll_to_top_text" />{' '}
                </a>
              </span>
            </p>
          </div>
          <br />
        </div>
      ),
    },
    WebAddressIncomplete: {
      title: 'Invalid URL',
      errorMessage: (
        <div className="first-error-invalid-page-wrapper">
          <div className="grid-container" data-test-id="ERROR_PAGE_HEADER">
            <h1 className="header">
              <FormattedMessage id="invalid_url_heading" />
            </h1>
          </div>
          <hr />
          <div className="grid-container">
            <p className="text-align">
              <span
                className="error-page-subheading"
                data-test-id="ERROR_MESSAGE_SPAN_1"
              >
                <FormattedMessage id="invalid_url_subheading" />
                <br />
                <br />
              </span>
              <span className="first-para" data-test-id="ERROR_MESSAGE_SPAN_2">
                {' '}
                <FormattedMessage
                  id="invalid_url_para1"
                  values={{ ...richText }}
                />
                <br />
              </span>
              <span className="second-para" data-test-id="ERROR_MESSAGE_SPAN_3">
                <FormattedMessage
                  id="invalid_url_para2"
                  values={{ ...richText }}
                />
                <br />
              </span>
              {swa && error_state !== '404' && (
                <span
                  className="third-para"
                  data-test-id="ERROR_MESSAGE_SPAN_4"
                >
                  <FormattedMessage
                    id="invalid_url_para3"
                    values={{ ...richText }}
                  />
                  <br />
                  <span className="fourth-para">
                    <h3 className="h3-more-info">
                      <FormattedMessage id="more_info_text_invalid" />
                    </h3>
                    <ul className="list-style">
                      <span className="bullet-color">
                        <FormattedMessage
                          id="moreinfo_link1_invalid"
                          values={{ ...richText }}
                        />
                      </span>
                      <FormattedMessage
                        id="moreinfo_link2_invalid"
                        values={{ ...richText }}
                      />
                    </ul>
                  </span>
                  <FormattedMessage
                    id="invalid_url_para4"
                    values={{ ...richText }}
                  />
                  <br />
                </span>
              )}
              <div className="scroll-top-height">
                <a className="scroll-top-color" onClick={scrollToTop} href="">
                  {' '}
                  <FormattedMessage
                    id="error_scroll_to_top_text"
                    values={{ ...richText }}
                  />{' '}
                </a>
              </div>
            </p>
          </div>
          <br />
        </div>
      ),
    },
    PageNotFound: {
      title: 'We seem to have a problem',
      errorMessage: (
        <div className="first-error-404-page-wrapper">
          <div data-test-id="ERROR_PAGE_HEADER">
            <h1 className="grid-container header">
              <FormattedMessage id="error404_heading" />
            </h1>
          </div>
          <hr />
          <div className="grid-container">
            <p className="text-align">
              <span
                className="error-page-subheading"
                data-test-id="ERROR_MESSAGE_SPAN_1"
              >
                <FormattedMessage
                  id="error404_subheading"
                  values={{ ...richText }}
                />
                <br />
              </span>
              <span className="first-para" data-test-id="ERROR_MESSAGE_SPAN_2">
                <FormattedMessage
                  id="error404_para1"
                  values={{ ...richText }}
                />
                <a href={url} className="link-decoration">
                  <FormattedMessage
                    id="choice_page_link"
                    values={{ ...richText }}
                  />
                </a>
                <br />
                <h3 className="h3-more-info">
                  <FormattedMessage
                    id="error404_more_info_text"
                    values={{ ...richText }}
                  />
                </h3>
              </span>
              <span className="second-para" data-test-id="ERROR_MESSAGE_SPAN_3">
                <p>
                  <FormattedMessage
                    id="error404_para2"
                    values={{ ...richText }}
                  />
                </p>
              </span>
              <span className="third-para" data-test-id="ERROR_MESSAGE_SPAN_4">
                <p className="contact-list">
                  <FormattedMessage id="login_gov_help_txt" />
                </p>
                <ul className="login-list-style">
                  <li>
                    <a href={login_link}>
                      <FormattedMessage id="login_link" />
                    </a>
                  </li>
                </ul>
              </span>
              <span className="fourth-para">
                <h3 className="h3-more-info">
                  <FormattedMessage id="more_info_text_404" />
                </h3>

                <ul className="list-style">
                  <span className="bullet-color">
                    <FormattedMessage
                      id="moreinfo_link1"
                      values={{ ...richText }}
                    />
                  </span>
                  <FormattedMessage
                    id="moreinfo_link2"
                    values={{ ...richText }}
                  />
                </ul>
                <br />
              </span>
              <span className="scroll-top-height">
                <a className="scroll-top-color" onClick={scrollToTop} href="">
                  {' '}
                  <FormattedMessage id="scroll_to_top_text" />{' '}
                </a>
              </span>
            </p>
          </div>
          <br />
        </div>
      ),
    },
    HitaWall: {
      title: 'We seem to have a problem',
      errorMessage: (
        <div className="first-error-page-wrapper">
          <div
            className="grid-container error-page-title-section header"
            data-test-id="ERROR_PAGE_HEADER"
          >
            <h1 className="error-page-title">{msgHeading}</h1>
          </div>
          <hr />
          <div className="grid-container error-page-paragraph">
            <p className="text-align">
              <span
                className="error-page-subheading"
                data-test-id="ERROR_MESSAGE_SPAN_1"
              >
                {msg}
                <br />
              </span>
              <span className="first-para" data-test-id="ERROR_MESSAGE_SPAN_2">
                <FormattedMessage
                  id="hitawall_para1"
                  values={{ ...richText }}
                />
                <br />
              </span>
              <span className="second-para" data-test-id="ERROR_MESSAGE_SPAN_3">
                <FormattedMessage
                  id="hitawall_para2"
                  values={{ ...richText }}
                />
                <br />
              </span>
              <span className="third-para" data-test-id="ERROR_MESSAGE_SPAN_4">
                <br />
                <FormattedMessage
                  id="hitawall_para3"
                  values={{ ...richText }}
                />
              </span>
              {hybridCode !== '16' &&
                (swa in states
                  ? states[swa]?.showErrorPageButton_HitaWall
                  : true) && (
                  <span className="error-page-button">
                    <Button type="primary">
                      <a
                        href={url}
                        data-testid="enrollment-diff-verification"
                        onClick={() =>
                          gaEventTracker(
                            `${swa}-Choose different button in error page`
                          )
                        }
                      >
                        <FormattedMessage id="different_verification_method_link" />
                      </a>
                    </Button>
                  </span>
                )}
              <span className="fourth-para">
                <FormattedMessage
                  id="hitawall_para4"
                  values={{ ...richText }}
                />
              </span>
              <h3 className="h3-more-info">
                <FormattedMessage id="hitawall_more_info_text" />
              </h3>

              <ul className="hitawall-list-style">
                <span className="bullet-color">
                  <FormattedMessage
                    id="hitawall_para5"
                    values={{ ...richText }}
                  />
                </span>
                <span className="fifth-para">
                  <FormattedMessage
                    id="hitawall_para6"
                    values={{ ...richText }}
                  />
                </span>
              </ul>
              <span>
                <FormattedMessage
                  id="hitawall_para7"
                  values={{ ...richText }}
                />
              </span>
              <br />
              <span className="scroll-top-height">
                <a className="scroll-top-color" onClick={scrollToTop} href="">
                  {' '}
                  <FormattedMessage
                    id="scroll_to_top_text"
                    values={{ ...richText }}
                  />{' '}
                </a>
              </span>
            </p>
          </div>
          <br />
        </div>
      ),
    },
    OutOfNetwork: {
      title: 'No Participating Post Offices Found',
      errorMessage: (
        <div className="usps-page-wrapper">
          <div data-test-id="ERROR_PAGE_HEADER">
            <h1 className="grid-container header">
              <FormattedMessage id="out_of_network_heading" />
            </h1>
          </div>
          <hr />
          <div className="grid-container">
            <p className="text-align">
              <span
                className="error-page-subheading"
                data-test-id="ERROR_MESSAGE_SPAN_1"
              >
                <FormattedMessage
                  id="out_of_network_subheading"
                  values={{ ...richText }}
                />
                <br />
              </span>
              <span
                className="error-page-text"
                data-test-id="ERROR_MESSAGE_SPAN_2"
              >
                <FormattedMessage
                  id="out_of_network_para_text1"
                  values={{ ...richText }}
                />
              </span>
              <hr className="usps-mid-hr" />
              <div className="user-details-wrapper">
                <div className="id-wrapper">
                  <div className="field-wrapper">
                    <h3
                      className="id-details"
                      data-test-id="id-details-personal-info"
                    >
                      <FormattedMessage id="enrollment_personal_information_text" />
                    </h3>
                    <div
                      id="edit-address"
                      className="edit-wrapper desktop"
                      data-test-id="edit-address"
                      onClick={() => {
                        gaEventTracker(`${swa}-Edit Identification Details`);
                        navigate(
                          `/search/${swa}/?swa_xid=${swa_xid}&lang_cd=${
                            lang_cd || 'en'
                          }`
                        );
                      }}
                    >
                      <EditIcon aria-label="Edit Address " />
                      <button
                        className="edit-id-details"
                        data-test-id="ENROLLMENT_EDIT_BUTTON"
                      >
                        <FormattedMessage id="enrollment_id_details_editicon_text" />
                      </button>
                    </div>
                  </div>
                  <div className="name-field-wrapper">
                    <FormattedMessage
                      id="enrollment_id_details_name_bold_text"
                      values={{ ...richText }}
                    />
                    <span
                      className="fullname-wrapper-input"
                      data-test-id="fullname-wrapper-input"
                    >{`${myFirstName} ${myLastName}`}</span>
                  </div>
                  <div className="email-field-wrapper">
                    <FormattedMessage
                      id="enrollment_id_details_email_bold_text"
                      values={{ ...richText }}
                    />
                    <span
                      className="email-wrapper-input"
                      data-test-id="email-wrapper-input"
                    >
                      {myEmail}
                    </span>
                  </div>
                  <div className="legal-address-wrapper">
                    <span className="address-wrapper">
                      <FormattedMessage
                        id="enrollment_id_details_address_bold_text"
                        values={{ ...richText }}
                      />
                    </span>
                    <span
                      className="field-wrapper-input"
                      data-test-id="field-wrapper-input"
                    >
                      {' '}
                      {myStreetAddress1} {myStreetAddress2}, {myCity}, {myState}{' '}
                      {myZipcode}
                    </span>
                  </div>
                </div>
              </div>
              <div
                id="edit-address"
                className="edit-wrapper mobile"
                data-test-id="edit-address-mobile"
                onClick={() => {
                  navigate(
                    `/search/${swa}/?swa_xid=${swa_xid}&lang_cd=${
                      lang_cd || 'en'
                    }`
                  );
                }}
              >
                <EditIcon aria-label="Edit Address " />
                <button className="edit-id-details">
                  <FormattedMessage id="enrollment_id_details_editicon_text" />
                </button>
              </div>
              <hr className="usps-mid-hr" />
              <div className="first-para" data-test-id="ERROR_MESSAGE_SPAN_2">
                {(swa in states
                  ? states[swa]?.showVerificationButton
                  : true) && (
                  <span className="error-page-button1">
                    <FormattedMessage
                      id="out_of_network_para1"
                      values={{ ...richText }}
                    />
                    <br />
                    <br />
                    <span className="error-page-button2">
                      <Button type="primary">
                        <a
                          className="usps-link-text"
                          href={url}
                          data-testid="enrollment-diff-verification"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Choose different button in error page`
                            )
                          }
                        >
                          <FormattedMessage id="different_verification_method_link" />
                        </a>
                      </Button>
                    </span>
                    <br />
                  </span>
                )}
              </div>
              <span className="second-para" data-test-id="ERROR_MESSAGE_SPAN_3">
                <FormattedMessage
                  id="out_of_network_para2"
                  values={{ ...richText }}
                />
              </span>
              <h3 className="h3-more-info">
                <FormattedMessage id="out_of_network_more_info_text" />
              </h3>
              <ul className="list-style">
                <span
                  className="bullet-color"
                  data-test-id="ERROR_MESSAGE_SPAN_4"
                >
                  <FormattedMessage
                    id="out_of_network_para3"
                    values={{ ...richText }}
                  />
                </span>
                <FormattedMessage
                  id="out_of_network_para4"
                  values={{ ...richText }}
                />
              </ul>

              <span className="scroll-top-height">
                <a className="scroll-top-color" onClick={scrollToTop} href="">
                  {' '}
                  <FormattedMessage
                    id="scroll_to_top_text"
                    values={{ ...richText }}
                  />{' '}
                </a>
              </span>
            </p>
          </div>
          <br />
        </div>
      ),
    },
  };

  let renderedPage = null;
  if (sessionStorage.state_errorType) {
    state = JSON.parse(sessionStorage.getItem('state_errorType'));
  }
  if (state) {
    renderedPage = ErrorDetails[state.type].errorMessage;
  } else if (!swa) {
    renderedPage = ErrorDetails.WebAddressIncomplete.errorMessage;
  } else if (unSuccessfulLogin === true) {
    renderedPage = ErrorDetails['LoginUnsuccessful'].errorMessage;
  } else {
    renderedPage = ErrorDetails.PageNotFound.errorMessage;
  }

  return (
    <div className="error-main-container" id="mainContent">
      <div>{renderedPage}</div>
    </div>
  );
};

export default Error;
