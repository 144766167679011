import React, { useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  Collapse,
  Stack,
  Grid,
  Button,
  Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from 'react-intl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HoursDisplay from './HoursList';
import './address-list.scss';
import { ReactComponent as HoursIcon } from '../../../icons/hours.svg';
import { ReactComponent as DistanceIcon } from '../../../icons/distance.svg';
import { ReactComponent as DirectionIcon } from '../../../icons/direction.svg';
import { ReactComponent as PhoneIcon } from '../../../icons/phone.svg';
import { ReactComponent as ParkingIcon } from '../../../icons/parking.svg';

function AddressList(props) {
  const { postOffices, lang_cd } = props;
  const linkRefs = useRef([]);
  const [expanded, setExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const handleToggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const initialShowCont = 5;

  const handleChange = (index) => {
    if (index === expanded) {
      setExpanded(-1);
    } else {
      setExpanded(index);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      setTimeout(() => {
        linkRefs.current[index].focus();
      }, 200);
    }
    if (e.key === 'Tab') {
      setExpanded(-1);
    }
  };

  return (
    <Box
      className="address-list"
      data-test-id="LISTOFPOSTOFFICES"
      sx={{ maxWidth: '46.5625em' }}
    >
      {postOffices
        ?.slice(0, showAll ? postOffices.length : initialShowCont)
        .map((postOffice, index) => {
          const {
            parking,
            hours,
            distance,
            phone,
            name,
            streetAddress,
            city,
            state,
            zip5,
          } = postOffice;
          return (
            <Accordion
              sx={{ width: '100%', outline: 'none' }}
              key={`${index}${expanded === index}`}
              expanded={expanded === index}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onChange={() => handleChange(index)}
              disableGutters
              data-testid={`address-${index}`}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon data-testid={`expand-icon-${index}`} />
                }
                aria-controls="panel1-content"
                data-test-id="postOffice-header"
                display="flex"
                alignItems="flex-start"
                data-testid={`postOffice-header-${index}`}
                p={2}
                sx={{
                  backgroundColor: expanded !== index ? '#fff' : '#D9E8F6',
                  '&:hover': {
                    backgroundColor: '#d9e8f6',
                  },
                  '&:focus-within': {
                    outline: 'none',
                    backgroundColor: '#d9e8f6',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    alignSelf: 'flex-start',
                    marginTop: '1rem',
                  },
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    className="post-accordian-index"
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    alignSelf="flex-start"
                  >
                    <Typography sx={{ fontWeight: 'bold', width: '2rem' }}>
                      {index + 1}.
                    </Typography>
                  </Grid>
                  <Grid container item xs={11} sm={11} md={11} spacing={2}>
                    <Grid item xs={16} md={6}>
                      <Stack
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                        sx={{ width: '100%' }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <DistanceIcon />
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {distance}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{ height: '1.5em', width: '1.5625em' }}
                          ></Box>
                          <Typography
                            sx={{ fontWeight: 'bold' }}
                            data-testid={`address-post-office-${index}`}
                          >
                            {name} -
                            <FormattedMessage id="address_list_post_office" />
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          tabIndex={1}
                          autoFocus={index}
                        >
                          <Box width={30}>
                            <DirectionIcon />
                          </Box>
                          <Link
                            tabIndex={-1}
                            onKeyDown={(e) => {
                              if (event.key === 'Enter') {
                                window.open(e.target.href);
                              }
                            }}
                            autoFocus={index}
                            id={`address-link-${index}`}
                            ref={(el) => {
                              linkRefs.current[index] = el;
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            target="_blank"
                            href={`https://maps.google.com/?q=${streetAddress} ${city} ${state} ${zip5}`}
                            sx={{
                              '& :focus': { outline: '0.0625em solid #000' },
                            }}
                            data-testid={`direction-${index}`}
                          >
                            {streetAddress}, {city}, {state} {zip5}
                          </Link>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={16} md={6}>
                      <Stack
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                        sx={{ width: '100%' }}
                      >
                        <Stack
                          direction="column"
                          spacing={1}
                          justifyContent={'stretch'}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <HoursIcon />
                            <HoursDisplay
                              hoursData={hours}
                              isToday
                              lang_cd={lang_cd}
                              addressIndex={index}
                            />
                          </Stack>
                          <Collapse
                            in={expanded === index}
                            sx={{ paddingLeft: '2em', width: '100%' }}
                          >
                            <HoursDisplay
                              hoursData={hours}
                              lang_cd={lang_cd}
                              addressIndex={index}
                            />
                          </Collapse>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <PhoneIcon />
                          <Typography
                            href={`tel:${phone}`}
                            sx={{ paddingLeft: '0.3125em' }}
                            data-testid={`phone-number-${index}`}
                          >
                            {phone}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <ParkingIcon />
                          <Typography data-testid={`parking-${index}`}>
                            <FormattedMessage id="address_list_parking_lot" />
                            {parking === 'Lot' ? (
                              <FormattedMessage id="address_list_parking_lot_yes" />
                            ) : (
                              <FormattedMessage id="address_list_parking_lot_no" />
                            )}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          );
        })}
      <Stack
        direction="row"
        sx={{ width: '100%' }}
        justifyContent="center"
        alignItems="center"
      >
        {postOffices?.length > initialShowCont && (
          <Button
            data-test-id="view-more-button"
            className="view-more-button"
            onClick={handleToggleShowAll}
            disableRipple
            endIcon={
              showAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
          >
            {showAll ? (
              <FormattedMessage id="address_list_view_less" />
            ) : (
              <FormattedMessage id="address_list_view_more" />
            )}
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default AddressList;
