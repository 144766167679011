import axios from 'axios';
import { BASE_URL, getUrlParams } from '../utils/utils';

export const getCSRFToken = async () => {
  if (!window.csrf_token) {
    const config = {
      method: 'get',
      url: `${BASE_URL}v1/token`,
      withCredentials: true,
    };
    const response = await axios(config);
    window.csrf_token = response.data.csrf_token;
    return response.data.csrf_token;
  }

  return window.csrf_token;
};

export const getAddressList = (
  swa,
  swa_xid,
  payload = null,
  enrollment_code = null,
  token = null
) => {
  const config = {
    method: 'post',
    url: `${BASE_URL}v1/usps/facilities`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      SWA: swa,
      'swa-xid': swa_xid,
      'X-CSRFToken': window.csrf_token || token,
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': "frame-ancestors 'none';",
    },
  };

  if (payload) {
    config.data = payload;
  }

  if (enrollment_code) {
    config.url += `?enrollment-code=${enrollment_code}`;
  }

  return axios(config);
};

const getOptInApplicant = (payload) => {
  const config = {
    method: 'post',
    url: `${BASE_URL}v1/usps/opt-in?lang-cd=${getUrlParams().lang_cd}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      swa: payload.swa,
      'swa-xid': payload.swa_xid,
      'X-CSRFToken': window.csrf_token,
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': "frame-ancestors 'none';",
    },
    data: payload,
  };

  return axios(config);
};

const updateOptInApplicant = (payload, enrollmentCode) => {
  const config = {
    method: 'put',
    url: `${BASE_URL}v1/usps/opt-in/${enrollmentCode}?lang-cd=${
      getUrlParams().lang_cd
    }`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      swa: payload.swa,
      'swa-xid': payload.swa_xid,
      'X-CSRFToken': window.csrf_token,
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': "frame-ancestors 'none';",
    },
    data: payload,
  };

  return axios(config);
};

const emailUpdatedLocation = (payload, enrollmentCode, swa, swa_xid) => {
  const config = {
    method: 'post',
    url: `${BASE_URL}v1/usps/alternate-location/${enrollmentCode}?lang-cd=${
      getUrlParams().lang_cd
    }`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      swa: swa,
      'swa-xid': swa_xid,
      'X-CSRFToken': window.csrf_token,
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': "frame-ancestors 'none';",
    },
    data: payload,
  };

  return axios(config);
};

const getBarcodeImage = (enrollmentCode, swa, swa_xid) => {
  const config = {
    responseType: 'arraybuffer',
    method: 'get',
    url: `${BASE_URL}v1/usps/barcode?enrollment-code=${enrollmentCode}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'image/png',
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': "frame-ancestors 'none';",
      swa: swa,
      'swa-xid': swa_xid,
    },
  };
  return axios(config);
};

export const getServicesConfig = (swa) => {
  const config = {
    method: 'get',
    url: `${BASE_URL}v1/services/${swa}`,
  };
  return axios(config);
};

export {
  getOptInApplicant,
  emailUpdatedLocation,
  updateOptInApplicant,
  getBarcodeImage,
};
