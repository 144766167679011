import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as LanguageIcon } from '../icons/language.svg';
import { ReactComponent as LanguageHoverIcon } from '../icons/language-hover.svg';

const LanguageSelector = ({
  languageList,
  handleLanguageChange,
  handleHover,
  hover,
  handleOut,
  handleBlur,
  handleFocus,
  isFocused,
}) => {
  const defaultLang = 1;
  const handleLanguageIcon = (index) => {
    if (index === 0 && isFocused) {
      return (
        <LanguageHoverIcon
          style={{ margin: '0.5rem 0.8rem 0.5rem 1rem' }}
          aria-label="Language Black Icon"
        />
      );
    }
    if (index === 0 && hover === false) {
      return (
        <LanguageIcon
          style={{ margin: '0.5rem 0.8rem 0.5rem 1rem' }}
          aria-label="Language Icon"
        />
      );
    }

    if (index === 0 && hover === true) {
      return (
        <LanguageHoverIcon
          style={{ margin: '0.5rem 0.8rem 0.5rem 1rem' }}
          aria-label="Language Black Icon"
        />
      );
    }

    return null;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleHover();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: hover === true && '#F2F9FF',
        border:
          hover === true
            ? '0.125rem solid #CEDCED'
            : '0.125rem solid transparent',
        borderRadius: '0.25rem',
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleOut}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex="0"
      onKeyDown={handleKeyDown}
    >
      <FormControl
        variant="standard"
        sx={{
          minWidth: 100,
        }}
      >
        <Select
          onOpen={handleHover}
          onClose={handleOut}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          data-test-id="demo-simple-select"
          value={defaultLang}
          open={hover}
          style={{ backgroundColor: isFocused === true && '#F2F9FF' }}
          sx={{
            color: hover === true || isFocused === true ? '#162E51' : '#F2F9FF',
            path: {
              color: hover === true || isFocused === true ? '#162E51' : 'white',
            },
            '&::after': {
              borderBottom: '1rem solid #CEDCED',
            },
            '.MuiSelect-select': {
              display: 'flex',
            },
            '.MuiSelect-icon': {
              marginRight: '1rem',
            },
            width: '10rem',
          }}
          disableUnderline
          inputProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  textAlign: 'center',
                  border: '0.125rem solid #CEDCED',
                  alignItems: 'center',
                  backgroundColor: '#F2F9FF',
                  margin: '0',
                  padding: '0',
                  width: 'auto',
                  minWidth: '100%',
                  '&:focus': {
                    outline: 'none',
                  },
                },
              },
            },
          }}
          onChange={(e) => handleLanguageChange(e.target.value)}
        >
          {languageList.map((lang, index) => (
            <MenuItem
              key={lang.value}
              value={lang.value}
              style={lang.style}
              sx={{
                color: '#005ea2',
                outline: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: '#F2F9FF',
                  borderRight: '0.125rem solid #fff',
                  borderLeft: '0.125rem solid #fff',
                  outline: 'none',
                },
                '&:focus': {
                  backgroundColor: 'transparent',
                  borderRadius: '0.375rem',
                  border: '0.125rem solid #2491ff',
                  outline: hover && 'none',
                },
              }}
              tabIndex={0}
            >
              {handleLanguageIcon(index)}
              {lang.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelector;
