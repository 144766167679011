export default {
  // App level components texts
  header_text: 'Unemployment claim identity verification at a Post Office',
  header_text_sup: 'TM',
  enrollment_component_heading:
    'Next steps for unemployment claim identity verification',
  reports_component_heading: 'USPS in person verification report',
  updatedTechnicalHeader_text: 'USPS in person verification technical report',
  of_text: 'of',
  back_text: 'BACK',

  // Enrollment component
  post_office_visit_text: 'Post Office<sup>TM</sup> visit confirmation',
  enrollment_heading_text: 'Visit the Post Office<sup>TM</sup> by',
  enrollment_description_para1:
    '  You’re about to confirm your identity for your <b>unemployment claim</b> with USPS. You will receive a copy of this via email. Bring this page or the email with you to the Post Office<sup>TM</sup>. You can also show a screenshot of the barcode.',
  enrollment_code_bold_text: '<b>Enrollment Code</b>',
  enrollment_description_para2_boldText:
    ' Your barcode and enrollment will expire after ',
  enrollment_description_para2_boldText1: ' days.',
  enrollment_description_para2:
    'If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you`ve received from the Unemployment Insurance Program.',
  print_this_pageBtn_text: 'Print this page',
  enrollment_different_verification_method_link:
    'Choose a different verification method',
  enrollment_personal_information_text: 'Personal information',
  enrollment_id_details_name_bold_text: '<b>Name:</b>',
  enrollment_id_details_email_bold_text: '<b>Email:</b>',
  enrollment_id_details_address_bold_text: '<b>Address:</b>',
  enrollment_id_details_editicon_text: 'Edit',
  USPS_facilities_located_around: 'Post Offices near:',
  USPS_facilities_hybrid: 'Post Offices near your address:',
  address_list_view_less: 'View Less',
  address_list_view_more: 'View More',
  address_list_parking_lot: 'Parking Lot: ',
  address_list_post_office: ' Post Office',
  address_list_office_hours: 'Closed',
  address_list_today_office_hours: 'Open Until ',
  address_list_tomorrow_label: 'Tomorrow',
  alternate_address_heading_text:
    'Find alternative participating post offices locations for your unemployment claim identity verification.',
  alternate_address_helper_text: `If your current address doesn't match your Government ID, you have the option to explore alternative  participating post office locations tailored to your preferences. This ensures a smooth identity verification process for your unemployment claim with USPS.`,
  alternate_address_helper_text2:
    'Enter an address to find a Post Office near you.',
  alternate_address_address_search_button: 'Find nearby USPS location',
  alternate_address_form_submit_button: 'Search',
  alternate_address_email_button: 'Email updated location',
  alternate_address_email_sent_message:
    '<b>Updated locations have been sent!</b>',
  alternate_address_email_sent_error_message: '<b>Email sending failed.</b>',
  alternate_address_noPostOffice_header_message:
    'There are no participating Post Offices within 50 miles of your address to complete in-person ID verification with USPS at this time.',
  alternate_address_noPostOffice_para1_message:
    'If you believe that the address provided above is incorrect, please update in order to locate nearby Post Offices.',
  alternate_address_noPostOffice_para2_message:
    'If the information you have entered is correct, you can choose a different verification method.',
  USPS_facilities_located_around_count_text1: 'There are ',
  USPS_facilities_located_around_count_text2:
    ' participating Post Offices within 50 miles of ',
  address_list_parking_lot_yes: 'Yes',
  address_list_parking_lot_no: 'No',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',

  // AddressSearch component
  personal_info_heading_text: 'Personal information',
  heading_helper_text:
    'Enter your name and address exactly as they appear on your government-issued ID.',
  form_required_info_text: 'Mandatory fields below are marked with an asterisk',
  input_first_name: 'First name',
  input_first_name_placeholder: 'Enter first name here',
  input_last_name: 'Last name',
  input_last_name_placeholder: 'Enter last name here',
  input_email: 'Email',
  input_email_placeholder: 'Enter email here',
  input_street_address_1: 'Address',
  input_street_address_1_placeholder: 'Enter address here',
  input_street_address_2: 'Apartment, suite, unit, etc.',
  input_street_address_2_placeholder: 'Enter address here',
  input_city_name_1: 'City',
  input_city_name_placeholder: 'Enter city here',
  input_state_name: 'State',
  input_state_name_placeholder: 'State',
  Select_State_DropdownText: 'Select state here',
  input_zipcode: 'Zip code',
  input_zipcode_placehoder: 'Enter zip code here',
  form_submit_button: 'Submit',
  different_verification_method_link: 'Choose a different verification method',
  important_message: ' ',

  // UICP component
  uicp_header: 'Unemployment claim identity verification',
  identity_text: 'Select the best identity verification option',
  online_radiobutton_label_text: 'Online - Login.gov',
  online_radiobutton_des_text:
    ' Use Login.gov to upload photos of your identity documents with your phone or computer.',
  online_radiobutton_des_main_text:
    'Use Login.gov to upload photos of your identity documents with your computer and/or phone.',
  online_radiobutton_des_biometric_text:
    '{br}To complete the login.gov ID verification you must have a <b>valid phone number</b> and you must complete the process on the login.gov website by clicking <b>“Agree and continue” to share your information with the US department of labor.</b>',
  online_radiobutton_des_unavailable_text:
    'Verifying your identity with Login.gov is currently unavailable.{br}{br}',
  online_radiobutton_doc_list_title: '<b>Accepted ID documents</b>',
  online_radiobutton_doc_list_text_first: '<li>State Government ID</li>',
  online_radiobutton_doc_list_text_second: ' ',
  usps_radiobutton_label_text: 'In-person - Post Office<sup>TM</sup>',
  usps_radiobutton_des_text:
    'Bring your identity documents to a participating Post Office<sup>TM</sup>.',
  usps_radiobutton_des_unavailable_text:
    'Verifying your identity with USPS is currently unavailable.{br}{br}',
  usps_radiobutton_doc_list_title: '<b>Accepted ID documents</b>',
  usps_radiobutton_doc_list_text_first: "State driver's license",
  usps_radiobutton_doc_list_text_second: 'State ID card',
  usps_radiobutton_doc_list_text_third:
    'U.S. Armed Forces or uniformed service ID card (requires secondary ID)',
  usps_radiobutton_doc_list_text_fourth:
    'U.S. Passport (requires secondary ID)',

  workforce_radiobutton_label_text:
    '   I want to verify my identity in-person at an Workforce Center',
  workforce_radiobutton_des_text:
    '  Bring your identity documents to the nearest Workforce Center.',
  workforce_radiobutton_doc_list_title: '<b>Accepted ID documents</b>',
  workforce_radiobutton_doc_list_extra_title: ' ',
  workforce_radiobutton_doc_list_extra_text: ' ',
  workforce_radiobutton_doc_list_text_first: '<li>State Government ID</li>',
  workforce_radiobutton_doc_list_text_second:
    '<li>State or tribal nation ID card</li>',
  workforce_radiobutton_doc_list_text_third:
    '<li>U.S. Armed Forces or uniformed service ID card (requires proof of address)</li>',
  workforce_radiobutton_doc_list_text_fourth:
    '<li>U.S. Passport or passport card (requires proof of address)</li>',
  workforce_radiobutton_doc_list_text_fifth:
    '<li>U.S. Permanent resident card or green card (requires secondary ID)</li>',
  workforce_radiobutton_doc_list_text_sixth: '<li>State school ID</li>',
  workforce_radiobutton_doc_list_text_seven:
    '<li>Concealed handgun license</li>',
  workforce_radiobutton_doc_list_text_eight: '<li>Medical marijuana card</li>',

  base_radiobutton_des_unavailable_text_first:
    'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}',
  base_radiobutton_des_unavailable_text_second:
    'Please try again later or choose a different verification method.{br}',
  base_radiobutton_des_unavailable_text_third:
    'Thank you for your patience and understanding.',
  uicp_section_next_button_text: 'Next',
  uicp_identify_footer_text2:
    'State staff will process your application following all applicable laws and regulations.',
  uicp_identify_footer_text3:
    'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity:',
  uicp_identity_footer_text:
    'The method you choose to verify your identity will not affect your ability to receive unemployment benefits. State staff will process your application following all applicable laws and regulations.',
  scroll_to_top_text: 'Return to top',
  error_scroll_to_top_text: 'Return to top',

  unemployment_question1: ' ',
  unemployment_question2: ' ',
  department_workforce: ' ',

  // Logout component
  logout_title: 'You have been logged out from login.gov',
  logout_intro: ' If you have done the steps below you may close this window.',

  // Confirmation Component
  confirmation_link_item_third: 'Logout',
  confirmation_header:
    'Next steps for unemployment claim identity verification',
  confirmation_name: ', your identity verification has been submitted.',
  confirmation_checkmark: ' We shared your identity verification with the',
  confirmation_swa_id_text:
    'The code used to verify your identity is the following:',
  confirmation_next_step_text: 'Next Steps',
  confirmation_steps_content_line_first: ' ',
  confirmation_steps_content_line_second: ' ',
  confirmation_Email_me_btn_text: 'Email me this page',
  confirmation_date_seperator: 'on',
  confirmation_state_title: ' ',
  confirmation_button_text: ' ',
  confirmation_content_extra: ' ',
  confirmation_content_info: ' ',
  confirmation_steps_nextStep_line_first: ' ',
  confirmation_steps_nextStep_line_second: ' ',

  // usps-description component
  required_Identity_documents: 'Required Identity documents',
  acceptable_forms_of_ID: 'Acceptable forms of ID:',
  State_drivers_license: "State driver's license",
  state_id_card: 'State ID card',
  uniformed_id_cards:
    'U.S. Armed Forces or uniformed service ID card (requires secondary ID)',
  secondary_id: ' U.S. Passport (requires secondary ID)',
  required_docs_heading: ' We use the address on your ID for verification.{br}',
  required_docs_para:
    'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
  reqired_docs_list_lease: 'Lease, mortgage, or deed of trust',
  reqired_docs_list_voter: 'Voter registration card',
  reqired_docs_list_vehical: 'Vehicle registration card',
  reqired_docs_list_home: 'Home or vehicle insurance policy',
  postOffice_text: 'The Post Office<sup>TM</sup>',
  postOffice_span: 'will not accept:',
  list_social_security: 'Social security cards',
  list_credit_card: 'Credit card or utility bills',
  list_school_id: 'State School IDs',
  list_medical_marijuana: 'Medical marijuana cards',
  list_concealed_licence: 'Concealed carry licenses',
  list_expierd_forms: 'Expired forms of ID',
  uicp_state_title: ' ',
  uicp_state_number: ' ',
  uicp_state_timing_para: ' ',
  redirect_email: ' ',
  usps_state_email: ' ',
  usps_state_title: ' ',
  usps_state_number: ' ',
  usps_state_timing_para: ' ',
  usps_contact: ' ',
  uicp_state_email: ' ',

  // ErrorPage component
  error_invalid_login_para4: ' ',
  invalid_url_heading: 'Invalid URL',
  invalid_url_subheading:
    'It looks like the URL you entered is invalid or no longer exists.',
  invalid_url_para1:
    'We cannot locate your ID verification number associated with your unemployment application.{br}',
  invalid_url_para2:
    'The URL in your confirmation may have been entered incorrectly. Please double-check the URL and try again.{br}',
  invalid_url_para3: ' ',
  hitawall_login_heading: 'We seem to have a problem with Login.gov',
  hitawall_usps_heading: 'We seem to have a problem with USPS',
  hitawall_login_subheading:
    'Verifying your identity with Login.gov is currently unavailable.',
  hitawall_usps_subheading:
    'Verifying your identity with USPS is currently unavailable.',
  hitawall_para1:
    'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
  hitawall_para2: 'Thank you for your patience and understanding. ',
  hitawall_para3: ' ',
  hitawall_para4: ' ',
  hitawall_para5: ' ',
  out_of_network_heading: 'No Participating Post Offices found',
  out_of_network_subheading:
    'There are no participating Post Offices within 50 miles of your address to complete in-person ID verification with USPS at this time.{br}',
  out_of_network_para1:
    'If the information you have entered is correct and matches your government issued identification, you can choose a different verification method.',
  out_of_network_para2: ' ',
  out_of_network_more_info_text: ' ',
  out_of_network_para3: ' ',
  error404_heading: 'We seem to have a problem',
  error404_subheading: '404 - Page not found',
  error404_para1:
    'We can’t find the page you are looking for. Please check your URL or return to the ',
  choice_page_link: 'ID verification choice page.',
  login_gov_help_txt: 'For help with Login.gov:',
  login_link: 'https://www.login.gov/contact/',
  login_link_invalid: 'https://www.login.gov/contact/',
  more_information: ' ',
  more_info_text_invalid: ' ',
  more_info_text0: ' ',
  more_info_text1: ' ',
  more_info_text2: ' ',
  error_invalid_login_para5: ' ',
  error_invalid_login_para6: ' ',
  error_invalid_login_moreinfo_link1: ' ',
  error_invalid_login_moreinfo_link2: ' ',
  error_invalid_login_moreinfo_link3: ' ',
  more_info_text_404: ' ',
  hitawall_more_info_text: ' ',
  // Links below More Info
  uicp_more_info_text: 'More Information',
  moreinfo_link1: ' ',
  moreinfo_link2: ' ',
  moreinfo_link3: ' ',
  error_invalid_login: ' ',
  moreinfo_link1_500: ' ',
  moreinfo_link2_500: ' ',
  moreinfo_link3_500: ' ',
  hitawall_moreinfo_link1: ' ',
  hitawall_moreinfo_link2: ' ',
  hitawall_moreinfo_link3: ' ',
  href_link1: ' ',
  href_link2: ' ',
  href_link3: ' ',
  faq_link: ' ',
  moreinfo_link1_invalid: ' ',
  moreinfo_link2_invalid: ' ',
  moreinfo_link3_invalid: ' ',
  out_of_network_para_text1:
    'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.',
  // Address Modal
  address_modal_header: 'Enter new address',
  address_modal_subheader:
    'Search for USPS facilities to verify your identity in a different location.',
  address_modal_zip: 'Zip',
  address_modal_search_button: 'Search',

  // Language Modal
  header_usdol_menu: 'MENU',
  language_label_header: 'Language',
  language_label_english: 'English',
  language_label_spanish: 'Spanish',
  language_modal_header: 'Language change',
  language_modal_para1:
    'By clicking continue and changing language this page will reload and you will lose any information you have entered on this page.',
  language_modal_para2:
    'Any information entered on previous pages has been saved and will not be lost.',
  language_continue_button: 'Continue',
  language_back_button: 'Back',

  // FE Validation Errors
  fn_mainError: 'Please enter your first name',
  fn_subError:
    'Allowed characters A-Z {br} You may also include hyphens, apostrophes and spaces',
  ln_mainError: 'Please enter your last name',
  ln_subError:
    'Allowed characters A-Z {br} You may also include spaces, periods, hyphens, and apostrophes',
  email_mainError: 'Your email must have a valid email format',
  email_subError: 'Enter your email address in format: yourname@example.com',
  addr1_mainError: 'Please enter your valid legal address',
  addr1_subError:
    'Allowed characters A-Z, 1-9 {br} You may also include spaces, hyphens, apostrophes, periods, forward slash “/” and number sign “#”',
  addr2_mainError: ' ',
  addr2_subError:
    'Allowed characters A-Z, 1-9 {br} You may also include spaces, hyphens, apostrophes, periods, forward slash “/” and number sign “#”',
  city_mainError: 'Please enter your city',
  city_subError:
    'Allowed characters A-Z {br} You may also include spaces, periods, hyphens, and apostrophes',
  zipcode_mainError: 'Please enter your Zip code',
  zipcode_subError: 'Up to 10-digit numeric including dashes',
  state_mainError: 'Please select your state from the options below',

  // Header Component
  banner_header_text: 'An official website of the United States government',
  banner_button_text: "Here's how you know",
  header_media_block: 'Official websites use .gov',
  header_media_block_body: 'Secure .gov websites use HTTPS',
  header_usdol_title: 'U.S. Department of Labor',
  banner_lock_description_two:
    " means you've safely connected to the .gov website. Share sensitive information only on official, secure websites.",
  banner_lock_description_main: 'A locked padlock',
  banner_lock_title: 'Lock',
  banner_lock_description_one: 'or',
  header_media_block_one: 'lock',
  header_media_block_two: 'A',
  header_media_block_three:
    ' website belongs to an official government organization in the United States.',
  // Footer Component
  footer_nav_federal: 'Federal Government',
  footer_nav_labor: 'Labor Department',
  footer_nav_about: 'About the Site',
  footer_connect_font: 'Connect with DOL',
  footer_findit: 'Site Map',
  footer_website_policies: 'Important Website Notices',
  footer_privacy_notice: 'Privacy & Security Statement',
  footer_dol_name_and_seal: 'U.S. Department of Labor',
  footer_secondary_link_white_house: 'White House',
  footer_secondary_link_corona_virus: 'Coronavirus Resources',
  footer_secondary_link_storm_recovery:
    'Severe Storm and Flood Recovery Assistance',
  footer_secondary_link_disaster_recovery: 'Disaster Recovery Assistance',
  footer_secondary_link_no_fear_act: 'No Fear Act Data',
  footer_secondary_link_special_counsel: 'U.S. Office of Special Counsel',
  footer_secondary_link_about_dol: 'About DOL',
  footer_secondary_link_guidance_search: 'Guidance Search',
  footer_secondary_link_inspector_general: 'Office of Inspector General',
  footer_secondary_link_dol_newsletter: 'Subscribe to the DOL Newsletter',
  footer_secondary_link_read_newsletter: 'Read the DOL Newsletter',
  footer_secondary_link_accountability_status:
    'Emergency Accountability Status Link',
  footer_secondary_link_a_to_z_index: 'A to Z Index',
  footer_secondary_link_information_act: 'Freedom of Information Act',
  footer_secondary_link_disclaimers: 'Disclaimers',
  footer_secondary_link_accessibility_statement: 'Accessibility Statement',

  // Workforce Component
  workforce_heading_text:
    'Unemployment claim identity verification at a local office',
  workforce_appointment_text: ' ',
  workforce_para1: ' ',
  workfore_para2: ' ',
  workfore_para3: ' ',
  workforce_location_link: 'Find my local Employment Department',

  // Error Component
  error_invalid_title: 'Online ID verification incomplete',
  invalid_login1:
    'Looks like something went wrong while you were creating your Login.gov account.',
  error_invalid_login1_para1:
    'To complete your ID verification with Login.gov you must:',
  error_invalid_login1_para1_list:
    '<li>Complete your registration with login.gov</li><li>Complete the ID verification process</li><li>Click “agree and continue” at the end if the ID verification process to share the results with the U.S. Department of Labor.</li>',
  invalid_login2:
    'Looks like something went wrong while you were verifying your ID through Login.gov.',
  error_invalid_login2_para1:
    'To complete your ID verification with Login.gov you must:',
  error_invalid_login2_para1_list:
    '<li>Complete the ID verification process,</li><li>Click “agree and continue” at the end if the ID verification process to share the results with the U.S. Department of Labor.</li>',
  invalid_login3:
    'Looks like you did not click on “agree and continue” after verifying your ID.',
  error_invalid_login3_para1:
    'To complete your ID verification with Login.gov you must click <b>“agree and continue”</b> at the end if the ID verification process to share the results with the U.S. Department of Labor.{br}',
  error_invalid_login_para1_subtext:
    'Failure to do so may result in delaying or denial of your unemployment benefits.',
  error_invalid_login_link: 'Sign in to Login.gov',
  error_invalid_login_para2:
    'If you’re having issues with Login.gov, please choose a different verification method to find the best option for verifying your identity for your unemployment claim.',
  error_invalid_login_forgot: 'Forgot my Login.gov password',
  error_invalid_login_para3:
    "Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:",
  error_500_title: 'We seem to have a problem',
  error_500_heading: 'ID verification is currently unavailable.',
  error_service_unavailable_heading:
    'Services to verify your identity are currently unavailable',
  error_500_para1:
    'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
  error_500_para2: 'Thank you for your patience and understanding.',
  error_500_para3: ' ',
  error404_more_info_text: ' ',
  logout_next_step_text: ' ',
  error_invalid_login_para7: ' ',
  fraud_modal_header:
    'Welcome to the United States Department of Labor Identification Verification Page!',
  fraud_modal_para1: ' ',
  fraud_modal_para2: ' ',
  fraud_modal_button: 'I understand',
  confirmation_scroll_to_top_text: 'Return to top',
  hitawall_para7: ' ',
  heading_helper_text1: ' ',
  heading_helper_text2: ' ',

  moreinfo_confirmation_link1: ' ',
  moreinfo_logout_link: ' ',
  hitawall_para6: ' ',
  fraud_modal_para1_usps: ' ',
  out_of_network_para4: ' ',
  para1_link_text: ' ',
  para3_link_text: ' ',
  workforce_more_info_text: ' ',
  workforce_moreinfo_link1: ' ',
  workforce_moreinfo_link2: ' ',
  workforce_moreinfo_link3: ' ',
  error_500_more_info_text1: ' ',
  error_500_more_info_text2: ' ',
  error404_para2: ' ',
  invalid_url_para4: ' ',
};

export const richText = {
  sup: (chunks) => <sup>{chunks}</sup>,
  li: (chunks) => <li>{chunks}</li>,
};
