import React from 'react';
import { Modal } from '@mui/material';
import './fraudPrevention-modal.scss';
import { FormattedMessage } from 'react-intl';
import { richText } from '../../i18n/states';

function FraudPreventionModal({
  showFraudPreventionModal,
  setShowFraudPreventionModal,
  page,
}) {
  const onFraudModalBtnClick = async () => {
    sessionStorage.setItem('auth_modal', 'true');
    setShowFraudPreventionModal(false);
  };

  return (
    <Modal open={showFraudPreventionModal} className="fraud-modal">
      <div
        className="fraud-modal-container"
        data-test-id="fraud-modal-container"
      >
        <div className="fraud-modal-header" data-test-id="fraud-modal-header">
          <p>
            <FormattedMessage
              id="fraud_modal_header"
              values={{ ...richText }}
            />
          </p>
        </div>
        <div className="fraud-modal-paragraph">
          <span>
            <p data-test-id="fraud-modal-para1">
              <FormattedMessage
                id={
                  page === 'usps'
                    ? 'fraud_modal_para1_usps'
                    : 'fraud_modal_para1'
                }
                values={{ ...richText }}
              />
              <br />
            </p>
          </span>
          <span>
            <p data-test-id="fraud-modal-para2">
              <FormattedMessage
                id="fraud_modal_para2"
                values={{ ...richText }}
              />
              <br />
              <br />
            </p>
          </span>
        </div>
        <div className="fraud-modal-btn-container">
          <button
            className="fraud-btn"
            onClick={() => {
              return onFraudModalBtnClick();
            }}
            data-testid="fraudBtn"
          >
            <FormattedMessage id="fraud_modal_button" />
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default FraudPreventionModal;
