import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: 0,
  zipcode: '',
  loading: false,
  data: {},
  error: false,
};

const addressSearchReducer = createSlice({
  name: 'addressSearch',
  initialState,
  reducers: {
    updateFormData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    // updateLoader: (state = initialState, {type, data}) => {
    //     switch(type){
    //         case 'loading':
    //          return {
    //           ...state,
    //           loading: true
    //          }

    //          case 'loaded':
    //          return {
    //           ...state,
    //           loading: false,
    //           data: data
    //          }

    //          case 'error':
    //          return {
    //           ...state,
    //           loading: false,
    //           error: data
    //          }
    //        }
    //     return { ...state, ...{type, data} };
    // },
  },
});

export const {
  updateFormData,
  // updateLoader,
} = addressSearchReducer.actions;

export default addressSearchReducer.reducer;
